// Package imports:
import React from 'react';
import cx from 'classnames';
interface IOwnProps {
    onClick?: () => void,
    open?: boolean,
    white?: boolean,
}

const Burger: React.FC<IOwnProps> = ({
    onClick,
    open,
    white
}) => (
        <div className='IR_burger'>
            <svg className={cx("ham hamRotate ham4",{'active' : open, 'white': white})} viewBox="0 0 100 100" width="50" onClick={onClick}>
                <path
                        className="line top"
                        d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                <path
                        className="line middle"
                        d="m 70,50 h -30 " />
                <path
                    className="line bottom"
                    d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
            </svg>
        </div>
  );

export default Burger;
