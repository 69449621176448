import React, { useEffect } from 'react';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import useComponentVisible from '../../../Hooks/ComponentVisible';
import { IDropDownData } from '../../../Pages/Authorized/IrSingleWidget';

interface ISelectDropDown {
    data?: IDropDownData[],
    title: string,
    returnValue: (e:string) => void,
    initialValue: string,
}

const LanguageDropDown: React.FC<ISelectDropDown> = ({
    data,
    title,
    returnValue,
    initialValue,
}) => {

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selected, setSelected] = React.useState<string>(initialValue)
    const [width, setWidth] = React.useState<number>(0)
    const specialID  = Math.random();

    useEffect(() => {
        const width = document.getElementById(`dropDownSelect_${specialID}`);
        let newNum = 1;
        if(width?.offsetWidth) newNum = width.offsetWidth - 2;
        setWidth(newNum)

    }, [isComponentVisible, specialID])
    
    return <div ref={ref} className='IR_selectDropDown language'>
        {/* button ting */}
        <div
            id={`dropDownSelect_${specialID}`}
            className={cx('selectedItem', {'visible': isComponentVisible})}
            onClick={() => setIsComponentVisible(!isComponentVisible)}>
            {
                <span style={{'fontSize': '15px','fontFamily': 'Poppins'}}><img alt="flag" src={data?.find(x => x.title === selected)?.imageSrc}/>{selected}</span>
            }
            <FontAwesomeIcon className={cx('fa-icon', {'is-open': isComponentVisible})} icon={faAngleDown} />
        </div>
        <label>{title}</label>
            {
                isComponentVisible
                ? <div
                    className='dropDownContainer'
                    style={{'width': (width !== 1 ? width : 'unset')}}>
                    {
                        data && data.map((item, ind) => (
                            <div
                                className={cx('dropdown_item', {'selected' : (selected === item.title)})}
                                style={{'fontSize': '15px', 'fontFamily': 'Poppins'}}
                                key={ind}
                                onClick={() => {
                                    setSelected(item.title);
                                    returnValue(item.locale);
                                    (item.title !== selected) ? setIsComponentVisible(false) : setIsComponentVisible(true);
                                }}><img alt="flag" src={item.imageSrc}/>{item.title}</div>
                            ))
                    }
                    </div>
                : null
            }
    </div>
}

export default LanguageDropDown;