import React, { useEffect } from 'react';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import useComponentVisible from '../../../Hooks/ComponentVisible';

interface ISelectDropDown {
    data: string[],
    title: string,
    type: "fonts" | "text-align",
    returnValue: (e:string) => void,
    initialValue: string,
}

const SelectDropDown: React.FC<ISelectDropDown> = ({
    data,
    title,
    type,
    returnValue,
    initialValue,
}) => {

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selected, setSelected] = React.useState<string>(initialValue)
    const [width, setWidth] = React.useState<number>(0)
    const specialID  = Math.random();

    useEffect(() => {
        const width = document.getElementById(`dropDownSelect_${type}${specialID}`);
        let newNum = 1;
        if(width?.offsetWidth) newNum = width.offsetWidth - 2;
        setWidth(newNum)

    }, [isComponentVisible, type, specialID])

    return <div ref={ref} className='IR_selectDropDown'>
        {/* button ting */}
        <div
            id={`dropDownSelect_${type}${specialID}`}
            className={cx('selectedItem', {'visible': isComponentVisible})}
            onClick={() => setIsComponentVisible(!isComponentVisible)}>
            {
                <span style={{'fontSize': '15px', 'fontFamily': (type === "fonts") ? selected : 'Poppins'}}>{selected}</span>
            }
            <FontAwesomeIcon className={cx('fa-icon', {'is-open': isComponentVisible})} icon={faAngleDown} />
        </div>
        <label>{title}</label>
            {
                isComponentVisible
                ? <div className='dropDownContainer'
                        style={{'width': (width !== 1 ? width : 'unset')}}>
                    {
                        data.map((item, ind) => (
                            <div
                                className={cx('dropdown_item', {'selected' : (selected === item)})}
                                style={{'fontSize': '15px','fontFamily': (type === "fonts") ? item : 'Poppins'}}
                                key={ind}
                                onClick={() => {
                                    setSelected(item);
                                    returnValue(item);
                                    (item !== selected) ? setIsComponentVisible(false) : setIsComponentVisible(true);
                                }}>{item}</div>
                            ))
                    }
                    </div>
                : null
            }
    </div>
}

export default SelectDropDown;