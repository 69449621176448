import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Clients from "../../../Components/Clients/Clients";
import Header from "../../../Components/Ui-elements/Header";
import Footer from "../../../Components/Ui-elements/Footer";
import Button from "../../../Components/Ui-elements/Button/Button";
import ParagraphWithImage from "../../../Components/Ui-elements/ParagraphWithImage/ParagraphWithImage";
import InterestedModal from "../../../Components/Ui-elements/InterestedModal/InterestedModal";

import stockLargeComputer from "../../../Assets/Images/stock-large-computer.png";
import marketData from "../../../Assets/Images/market-data-phone.png";
// import leftCube from "../../../Assets/Images/Kassarleft.svg";
// import rightCube from "../../../Assets/Images/Kassarright.svg";
import Hero_Frontpage from '../../../Assets/Images/hero-picture.png';
import Hero_Frontpage_mobile from '../../../Assets/Images/hero-picture-mobile.png';
import { Shell } from "../../../Components/Ui-elements/StyledComponents/StyledComponents";

const HomePage: React.FC = () => {
  const [scroll, setScroll] = useState(false);
  const [imageSrc, setImageSrc] = useState(Hero_Frontpage);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      offset: 400, // offset (in px) from the original trigger point
      delay: 250, // values from 0 to 3000, with step 50ms
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);
  
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     const scrollHeight = document.getElementsByClassName('hero')[0].scrollHeight
  //     setScroll(window.scrollY >   scrollHeight - 50 ?? 700);
  //   });
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
        const scrollHeight = document.getElementsByClassName('hero')[0].scrollHeight;
        setScroll(window.scrollY > scrollHeight - 50 ?? 700);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up function
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
}, []);


  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (windowWidth < 810) {
        setImageSrc(Hero_Frontpage_mobile); // Replace 'new-image.jpg' with the new image source
      } else {
        setImageSrc(Hero_Frontpage); // Replace 'default-image.jpg' with the default image source
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
    <div className="IR_homePage">
        <Header scroll={scroll} transparentTheme={true} />
      <div className="hero">
        <div className="hero-header">
          <h1>Build your IR Widgets visually</h1>
          <h3>and without any code</h3>
        </div>
        <div className="jc-center">
          <img className="hero_image" alt="frontpage_Hero" src={imageSrc} /> 
        </div>
        <p className="sub-text">No more complex software installations, server deployments and tedious coding.</p>
      </div>

      <main id="main">
        <section className="build-integrate">
          {/* <div className="rightCube">
            <img src={rightCube} alt="rightCube"/>
          </div> */}
          <div className="build-integrate-container">
            <h4>Build and integrate</h4>
            <h3>in only 30 Seconds!</h3>
            <div className="description-container">
              <h5>
                Our IR Widgets are a fresh approach to your investor relations. <br/>
                We offer IR services for all companies listed on Nasdaq Nordic.
              </h5>
              <p>(CPH, STO, HEL, ICE and First North)</p>
            </div>
            <div className="jc-center">
              <Button
                size="lg"
                buttonType="submit"
                url= "/get-started">
                  Get started
              </Button>
            </div>
          </div>
          {/* <div className="leftCube">
            <img src={leftCube} alt="leftCube"/>
          </div> */}
        </section>

        <div data-aos="fade-right" className="paragraphWrapper jc-center">
          <ParagraphWithImage
            info="overview"
            image={stockLargeComputer}
            title={<p>
              Live Market Data <br />
              Investor Relations Widget
            </p>}
            width="1250px"
            description={
              <p>
                Empower your IR website with our cutting-edge IR modules
                and interactive charts and deliver the financial
                information investors really care about.
              </p>}

            button={{
              title: 'More about the widgets',
              url: '/our-widgets',
              target: '_self'
            }}
            />
        </div>

        <div data-aos="fade-left" className="paragraphWrapper jc-center">
          <ParagraphWithImage
            reverse
            info="about"
            title="Trusted by many"
            image={marketData}
            description={
              <p>
                Live Market Data IR Widgets are developeded and supported
                by the Icelandic software company Kóði ehf. Our widgets
                are trusted by well-known brands in Iceland and Northern
                Europe.
              </p>}
            button={{
              title: 'More about us',
              url: '/about-us',
              target: '_self'}}
            />
        </div>
        <Shell style={{paddingTop: '0px'}}>
          <Clients />
        </Shell>
        <section className="interestedSection jc-center">
          <InterestedModal />
        </section>
      </main>
      <Footer/>
    </div>
    
    </>
  );
}

export default HomePage;
