import React, { useEffect } from 'react';
import Footer from '../../../Components/Ui-elements/Footer';
import Header from '../../../Components/Ui-elements/Header';
import HeadSection from '../../../Components/Ui-elements/HeadSection/HeadSection';
import { Shell } from '../../../Components/Ui-elements/StyledComponents/StyledComponents';

const PrivacyPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return <div className='IR_termsPage'>
        <Header scroll={true}/>
        <div className='headSectionWrapper'>
            <HeadSection
                theme='greenAndPurple'
                title={'Privacy'}
                question={'Privacy Policy'}
                description="This privacy policy applies between you, the User of this Website and Live Market Data, the owner and provider of this Website. Live Market Data takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the website."/>
        </div>
        <Shell style={{'paddingTop': '40px'}}>
            <div className='pageWrapper'>
                <div className='disclaimer'>
                    <p>
                        This privacy policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at: www.livemarketdata.com/terms.
                    </p>
                    <p>
                        Please read this privacy policy carefully.
                    </p>
                    <p>
                        the following definitions are used:
                    </p>
                    
                </div>

                {/* Data */}
                <div className='section'>
                    <h4>Data</h4>
                    <p>collectively all information that you submit to Live Market Data via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws</p>
                </div>

                <div className='section'>
                    <h4>Cookies</h4>
                    <p>A small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in the clause below (Cookies)</p>
                </div>

                <div className='section'>
                    <h4>Data Protection Laws</h4>
                    <p>any applicable law relating to the processing of personal Data, including but not limited to the Directive 96/46/EC (Data Protection Directive) or the GDPR, and any national implementing laws, regulations and secondary legislation, for as long as the GDPR is effective in Iceland.</p>
                </div>

                <div className='section'>
                    <h4>GDPR</h4>
                    <p>
                        the General Data Protection Regulation (EU) 2016/679; Live Market Data, we or us Live Market Data, a company incorporated in Iceland with registered number +354 562 2800 whose registered office is at Borgartún 25, 105 Reykjavík, Iceland.
                    </p>
                    <p>
                        Iceland and EU Cookie Law the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011
                    </p>
                </div>

                <div className='section'>
                    <h4>User or you</h4>
                    <p>any applicable law relating to the processing of personal Data, including but not limited to the Directive 96/46/EC (Data Protection Directive) or the GDPR, and any national implementing laws, regulations and secondary legislation, for as long as the GDPR is effective in Iceland.</p>
                </div>

                {/* Website */}
                <div className='section'>
                    <h3>Website</h3>
                    <p>
                        the website that you are currently using, www.livemarketdata.com, and any sub-domains of this site unless expressly excluded by their own terms and conditions.
                    </p>
                    <ol className='list'>
                        <li className='listItem'>
                            In this privacy policy, unless the context requires a different interpretation:
                            <ol>
                                <li>the singular includes the plural and vice versa.</li>
                                <li>references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of this privacy policy.</li>
                                <li>a reference to a person includes firms, companies, government entities, trusts and partnerships.</li>
                                <li>“including” is understood to mean “including without limitation”</li>
                                <li>reference to any statutory provision includes any modification or amendment of it;</li>
                                <li>the headings and sub-headings do not form part of this privacy policy.</li>
                            </ol>
                        </li>
                        <li className='listItem'>
                            This privacy policy applies only to the actions of Live Market Data and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.
                        </li>
                        <li className='listItem'>
                            For purposes of the applicable Data Protection Laws, Live Market Data is the “data controller”. This means that Live Market Data determines the purposes for which, and the manner in which, your Data is processed.
                        </li>
                    </ol>
                </div>

                {/* Data collected */}
                <div className='section'>
                    <h3>Data collected</h3>
                    <ol className='list'>
                        <li className='listItem'>
                            We may collect the following Data, which includes personal Data, from you:
                            <ol>
                                <li>Name.</li>
                                <li>Job title.</li>
                                <li>Contact Information such as email addresses and telephone numbers.</li>
                                <li>IP address (automatically collected).</li>
                                <li>Web browser type and version (automatically collected).</li>
                                <li>Operating system (automatically collected).</li>
                            </ol>
                        </li>
                        <li className='listItem'>
                            We collect Data in the following ways:
                            <ol>
                                <li>Data is given to us by you ; and.</li>
                                <li>Data is collected automatically.</li>
                            </ol>
                        </li>
                        <li className='listItem'>
                            We may suspend or cancel your registration with immediate effect for any reasonable purposes or if you breach these terms and conditions.
                        </li>
                        <li className='listItem'>
                            You may cancel your registration at any time by informing us in writing to the address at the end of these terms and conditions. If you do so, you must immediately stop using the Website. Cancellation or suspension of your registration does not affect any statutory rights.
                        </li>
                    </ol>
                </div>

                {/* Data that is given to us by you */}
                <div className='section'>
                    <h3>Data that is given to us by you</h3>
                    <ol className='list'>
                        <li className='listItem'>
                            when you contact us through the Website, by telephone, post, e-mail or through any other means
                        </li>
                        <li className='listItem'>
                            We assume no responsibility for the content of such websites and disclaim liability for any and all forms of loss or damage arising out of the use of them.
                        </li>
                        <li className='listItem'>
                            when you register with us and set up an account to receive our products/services
                        </li>
                    </ol>
                </div>

            </div>
        </Shell>
        <Footer />


    </div>

}

export default PrivacyPage;