import React, { useState, useEffect, useRef } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import Reaptcha from "reaptcha";

import Header from "../../../Components/Ui-elements/Header";
import Footer from "../../../Components/Ui-elements/Footer";
import HeadSection from "../../../Components/Ui-elements/HeadSection/HeadSection";
import SmallPrint from "../../../Components/Ui-elements/SmallPrint/SmallPrint";
import Button from "../../../Components/Ui-elements/Button/Button";
import Input from "../../../Components/Ui-elements/Input/Input";
import Alert from "../../../Components/Ui-elements/Alert/Alert";
import { IUserInfo } from "../../../Types/GeneralTypes";
import { FormModal, Page, ShellCentered } from '../../../Components/Ui-elements/StyledComponents/StyledComponents';

import { TOAST_DELAY } from "../../../Utils/func";

import Email from "../../../Assets/Images/email.svg";
import Office from "../../../Assets/Images/office.svg";
import Telephone from "../../../Assets/Images/telephone.svg";
import LiveChat from "../../../Assets/Images/live-chat.svg";

const Contact: React.FC<IUserInfo> = ({
  isAuthenticated
}) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [contactResponse, setContactResponse] = useState<JSX.Element>(<></>);
  const [showToast, setShowToast] = useState(true);

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const verify = () =>{
    //@ts-ignore
    captchaRef.current.getResponse().then(res => {
        setCaptchaToken(res)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const form = useRef(null);
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
        const url = `https://keldan.is/IrPage/SendEmail?type=Contact&name=${name}&company=${company}&phone=${phone}&email=${email}&body=${message}&captchaToken=${captchaToken}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            "Access-Control-Allow-Origin": "*"
            },
        });
        if (response.ok) {
            const responseBody: {success: boolean, message: string} = await response.json();
            if (responseBody.success) {
                setContactResponse(<Alert type={"success"} headText={"Email was sent"} />)
                setShowToast(true)
                setName("")
                setCompany("")
                setPhone("")
                setEmail("")
                setMessage("")
            } else {
              setContactResponse(<Alert type={"error"} headText={"Email was not able to send"} />)
              setShowToast(true)
            }
        } else {
          setContactResponse(<Alert type={"error"} headText={"Request failed"} />)
          setShowToast(true)
        }
    } catch (e) {
        setContactResponse(<Alert type={"error"} headText={"Network error"} />)
        setShowToast(true)
        console.log(e)
    }
  };

  return (
    <div className="IR_contact">
      <Header scroll={true} />
      <Page>
        <ShellCentered>
          <HeadSection
            title={"contact"}
            question={"Can we be of your service?"}
            description={"Get in touch with us and tell us more about how we can help your business."}/>
          <div className="formContainer">
            <FormModal>
              <form ref={form} className="contact-form" onSubmit={handleFormSubmit}>
                <div className="inputWrapper">
                  <Input
                    required={true}
                    type={"text"}
                    name={"fullName"}
                    id={"fullname"}
                    label={"Full name"}
                    value={name}
                    onChange={(a: string) => setName(a)} />
                  <Input
                    required={true}
                    type={"text"}
                    name={"company"}
                    id={"company"}
                    label={"Company"}
                    value={company}
                    onChange={(a: string) => setCompany(a)} />
                </div>
                <div className="inputWrapper">
                  <Input
                    required={true}
                    type={"text"}
                    name={"phone"}
                    id={"phone"}
                    label={"Phone number"}
                    value={phone}
                    onChange={(a: string) => setPhone(a)} />
                  <Input
                    required={true}
                    type={"email"}
                    name={"email"}
                    id={"email"}
                    label={"Email"}
                    value={email}
                    onChange={(a: string) => setEmail(a)} />
                </div>
                <Input
                  required={true}
                  type={"textarea"}
                  name={"message"}
                  id={"message"}
                  label={"Your message"}
                  value={message}
                  onChange={(a: string) => setMessage(a)} />
                <div style={{marginTop: '25px'}} className="jc-center">
                  <Reaptcha
                    className="g-recaptcha"
                    sitekey={'6LcamJokAAAAADXBk0plogjoCBuIdFcE3UWa9dvc'}
                    ref={captchaRef}
                    onVerify={verify}
                    isolated
                  ></Reaptcha>
                </div>
                <div style={{'marginTop': '30px'}}className="text-center jc-center">
                  <Button type="submit" buttonType="submit" size="lg">Send message</Button>
                </div>
              </form>
            </FormModal>
          </div>
          <div className="contact-smallPrint-container">
            <div className="smallPrint-wrapper">
              <div className="small-print">
                <SmallPrint
                  source={Telephone}
                  title={"Call us"}
                  description={<a href="tel:+3545622800">+354 562 2800</a>}
                  />
              </div>
              <div className="small-print">
                <SmallPrint
                  source={Email}
                  title={"Email us"}
                  description={<a href="mailto:sales@livemarketdata.com">sales@livemarketdata.com</a>}
                  />
              </div>
            </div>
            <div className="smallPrint-wrapper">
              <div className="small-print">
                <SmallPrint
                  source={Office}
                  title={"Visit the office"}
                  description={<a target="_black" href="https://www.google.com/maps/place/K%C3%B3%C3%B0i+ehf/@64.1466733,-21.9017443,17z/data=!3m1!4b1!4m5!3m4!1s0x48d674c422e55555:0xd44ff2a07262f3df!8m2!3d64.1466477!4d-21.8995462">
                    <span>Borgartún 25, 2nd floor,</span><br/>
                    <span>105 Reykjavík, Iceland</span></a>}
                    />
              </div>
              <div className="small-print">
                <SmallPrint
                  source={LiveChat}
                  title={"Live chat"}
                  description={<p className="live-chat">
                    Click the green icon at bottom to the right to chat with
                    a live person.
                  </p>}
                    />
              </div>
            </div>
          </div>
        </ShellCentered>
      </Page>
      <Footer/>
      <ToastContainer className='toastContainer' >
          <Toast onClose={() => setShowToast(false)} autohide delay={TOAST_DELAY} show={showToast}>
              {contactResponse}
          </Toast>
      </ToastContainer>
    </div>
  );
}

export default Contact;