import React, { useState } from 'react';
import {useAuthUser, useSignOut} from 'react-auth-kit'
import cx from 'classnames';
import logout_icon from '../../../Assets/Images/logout-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';


const SETTINGS_DROPDOWN_DATA: IDropDownData[] = [
    {
        title: 'Logout',
        imageSrc: logout_icon,
    },
]

interface IDropDownData {
    title: string,
    imageSrc?: string,
    locale?: string
    url?: string,
}

interface IProps {
    blackText?: boolean,
}


const SettingsDropDown:React.FC<IProps> = ({
    blackText = false
}) => {
    const signOut = useSignOut();
    const user = useAuthUser();
    const [visible, setVisible] = useState(false);



    return <div className='IR_dropDown settings' onMouseLeave={() => setVisible(false)}>
         <div
            onMouseOver={() => setVisible(true)}
            onClick={() => setVisible(!visible)}>
            <div className={cx('dropDown-btn', {'blackText': blackText})}>
                <div className='content'>
                    {/* <img alt="logo" src="" /> */}
                    <span className='user-email'>{user()?.email}</span>
                    <div className='rotateArrow' >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </div>
            </div>
        </div>
        {
            (visible)
                ? (
                    <div
                        className='dropDownContainer'
                        onMouseOver={() => setVisible(true)}
                        onMouseLeave={() => setVisible(false)}
                        >
                    {
                        SETTINGS_DROPDOWN_DATA.map((item, ind) => {
                            return <div key={ind} className='listItem dropDown-open nav-link' >
                                    { item.imageSrc && <FontAwesomeIcon className='listItem-image' icon={faSignOut} />
                                    // <img className='listItem-image' alt='list-item-flag' src={item.imageSrc} /> }
                        }
                                    {
                                        (item.title === 'Logout' )
                                        ? <span
                                            onClick={() => signOut()}
                                            className={cx('listItem-title', {'selected': item.locale === user()?.locale })}>
                                                {item.title}
                                        </span>
                                        : (item.url)
                                        ? <Link
                                            to={item.url}
                                            className={cx('listItem-title', {'selected': item.locale === user()?.locale })}>
                                                {item.title}
                                        </Link>
                                        : null
                                    }
                                </div>
                        })
                    }
                </div>
                ) : null
        }
        </div>

}

export default SettingsDropDown;