import React, { useEffect } from "react";

import Header from "../../../Components/Ui-elements/Header";
import Clients from "../../../Components/Clients/Clients";
import Footer from "../../../Components/Ui-elements/Footer";
import ParagraphWithImage from "../../../Components/Ui-elements/ParagraphWithImage/ParagraphWithImage";
import InterestedModal from "../../../Components/Ui-elements/InterestedModal/InterestedModal";
import { Page, ShellBody } from "../../../Components/Ui-elements/StyledComponents/StyledComponents";
import HeadSection from "../../../Components/Ui-elements/HeadSection/HeadSection";

import stockLargeComputer from "../../../Assets/Images/kodiWebsite.svg";
import backgroundImage from "../../../Assets/Images/BarChart-HeroImage.svg";
import { IUserInfo } from "../../../Types/GeneralTypes";

const AboutUs: React.FC<IUserInfo> = ({
  isAuthenticated
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="IR_aboutUs">
      <Header scroll={true} />
      <Page>
        <div className="hero">
          <div className="hero-text">
            <HeadSection
              title={"about us"}
              question={"We work closely with clients"} 
              description="We provide diversified services for professional and public
                investors, such as trading solutions, cloud-based market data,
                Excel plugins, and more."
              textAlign="left"
              theme="greenAndPurple"
              halfWidth
              button={{text: 'Contact us', url: '/contact'}}
              />
          </div>
            <img id="backgroundImage" alt="background" src={backgroundImage} />
        </div>
        <ShellBody>
          <div className="jc-center">
            <ParagraphWithImage
              info="MORE DETAILS"
              title="Live Market Data is powered by Kóði ehf."
              image={stockLargeComputer}
              button={{title: "View Kóði’s website", url: 'https://www.kodi.is/', target: "_blank"}}
              description={
                <p>
                  Kóði ehf. is a leading software company servicing the
                  Icelandic financial and corporate sectors. We are an
                  independent company focused on working closely with our
                  clients so they can carry out their work with solutions that
                  are reliable and meet the highest standards.
                </p>
              }/>
            </div>
          <div className="client-wrapper">
            <Clients />
          </div>
          <div className="interestedSection jc-center">
            <InterestedModal />
          </div>
        </ShellBody>
      </Page>
      <Footer/>
    </div>
  );
}

export default AboutUs