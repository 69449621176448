import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import cx from 'classnames';
import ScrollLock  from 'react-scrolllock';
import { useIsAuthenticated } from 'react-auth-kit'

import Button from './Button/Button';
import Logo from "./HeaderLogo/HeaderLogo";
import Burger from "./Burger/Burger";
import SettingsDropDown from "./DropDown/DropDown";

interface IProps{
  scroll?: boolean,
  transparentTheme?: boolean,
  authenticatedPage?: boolean
}
const Header:React.FC<IProps> = ({
  scroll,
  transparentTheme = false,
  authenticatedPage = false
}) => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated()
  const [mobile, setMobile] = useState(false);
  const navItems: {pathName: string, url: string, hasIcon?: boolean}[] = [];
   if(isAuthenticated() || mobile){
    navItems.push({
      pathName: 'IR widgets',
      url: '/our-widgets'
    }, {
      pathName: 'Pricing',
      url: '/pricing'
    }, {
      pathName: 'About us',
      url:'/about-us'
    }, {
      pathName: 'Contact',
      url: '/contact'
    })
  } else {
    navItems.push({
        pathName: 'IR widgets',
        url: '/our-widgets'
      }, {
        pathName: 'Pricing',
        url: '/pricing'
      }, {
        pathName: 'About us',
        url:'/about-us'
      }, {
        pathName: 'Contact',
        url: '/contact'
      }, 
      {
        pathName: 'Login',
        url: '/login',
        hasIcon: true
    })
  }

  let pathname = location.pathname;
  pathname = pathname.substring(1);
  window.onresize = () => {
    //screen width is higher than 1010 -> set mobile to false
    if(window.innerWidth > 1010){ setMobile(false) }
  }

  return (
    <>
    <header
      id="header"
      className={cx(`header fixed-top`, {'mobile': mobile, 'sticked': scroll, 'isAuthenticated': (authenticatedPage && isAuthenticated()) })}
      data-scrollto-offset={0} >
      <div className={`headerContent`}>
        <Link
          to="/"
          className="logo scrollto" >
          <Logo mobile={mobile} white={((transparentTheme && !scroll) || (authenticatedPage && isAuthenticated()))}/>
        </Link>
        <nav id="navbar" className="navbar">
          <ul className="navLinkContainer">
            {
              navItems.map((item, ind) => (
                <li key={ind}>
                  {
                    <Link
                      className={cx(
                          'nav-link scrollto',
                          { 'active-link': pathname === item.url.substring(1).toLowerCase() && item.pathName !== 'Log out',
                            'whiteText': (transparentTheme && !scroll) || (authenticatedPage && isAuthenticated())})}
                      to={item.url}>
                      {item.pathName}
                      {item.hasIcon && (
                        <span className="fa-icon faLock"><FontAwesomeIcon icon={faLock}/></span>
                      )}
                    </Link>
                  }
                </li>
              ))
            }
            {
              isAuthenticated()
              ? <>
                <div className={cx("prik", {'blackPrick': (transparentTheme && !scroll) || (authenticatedPage && isAuthenticated())})}></div>
                <li>
                    {
                      <Link
                        className={cx(
                            'nav-link authenticatedLink scrollto',
                            { 'active-link': pathname === 'all-ir-widgets',
                              'whiteText': (transparentTheme && !scroll) || (authenticatedPage && isAuthenticated())})}
                        to={'/all-ir-widgets'}>
                        All IR Widgets
                      </Link>
                    }
                  </li>
                </>
              : null
            }
          </ul>
          {
            !isAuthenticated() 
              ? <ul className="loginButton">
                <li>
                  {
                    <Link
                      className={cx(
                          'nav-link scrollto',
                          { 'active-link': pathname === 'login',
                            'whiteText': (transparentTheme && !scroll) || mobile})}
                      to={'/login'}>
                      Login
                      <span className="fa-icon faLock"><FontAwesomeIcon icon={faLock}/></span>
                    </Link>
                  }
                </li>
              </ul>
              : null
            }
          <div className="burgerWrapper">
            <Burger white={(transparentTheme && !scroll) || (authenticatedPage && isAuthenticated())} onClick={() => setMobile(!mobile)} open={mobile}/>
          </div>
          {
            (!isAuthenticated()) 
              ? <Button
                  className={cx({'onTop': mobile})}
                  buttonType={((transparentTheme && !scroll) || (authenticatedPage && isAuthenticated())) ? 'whiteAndBlue' :(!mobile) ? 'primary' : 'whiteAndBlue'} size="md" url= "/get-started">Get started</Button>
              : <div className="authenticatedButtons-wrapper">
                   <SettingsDropDown blackText={!(transparentTheme && !scroll) && !mobile && !authenticatedPage}/>
              </div>
          }
        </nav>
      </div>
    </header>
    <ScrollLock isActive={mobile}>
      <div className={cx("mobileMenuContainer", {'active': mobile})}>
        <ul className="navLinkContainer mobile">
          {
            navItems.map((item, ind) => (
              <li key={ind}>
                {
                  <Link
                  className={cx("nav-link scrollto", {'active-link': pathname === item.url.substring(1).toLowerCase()})}
                  to={item.url} >
                  <span className={cx("mobileMenuLink", {'active-link': pathname === item.url.substring(1).toLowerCase()})}>
                    {item.pathName}
                    {item.hasIcon && (
                      <span className="fa-icon faLock"><FontAwesomeIcon icon={faLock}/></span>
                    )}
                  </span>
                </Link>
                }
              </li>
            ))
          }
          {
            (isAuthenticated())
            ? <li>
              {
                <Link
                  className={cx("nav-link scrollto", {'active-link': pathname === 'all-ir-widgets'})}
                  to={"/all-ir-widgets"} >
                  <span className={cx("mobileMenuLink", {'active-link': pathname === 'all-ir-widgets'})}>
                    All IR Widgets
                  </span>
                </Link>
                }
              </li>
            : null
          }
        </ul>
      </div>
      </ScrollLock>
    </>
  );
}

export default Header;
