import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

//hooks import:
import useComponentVisible from '../../../Hooks/ComponentVisible';

interface IProps {
    title: string[],
    selectedWidget: string,
    setSelectedWidget: (widget: string) => void,
}

const DemosDropDown:React.FC<IProps> = ({
    title,
    selectedWidget,
    setSelectedWidget
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return <div ref={ref} className='IR_demoDropDown'>
        <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
            <div className={cx('dropDown-btn', {'visible': isComponentVisible})}>
                <div className='content'>
                    <span className='selectedWidget'>{selectedWidget.replaceAll('-', ' ')}</span>
                    <div className='rotateArrow' >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </div>
            </div>
        </div>
        {
            (isComponentVisible)
                ? (
                    <div className='dropDownContainer' >
                        {
                            title.map((item, ind) => (
                                 <div
                                    key={ind}
                                    onClick={() => {setIsComponentVisible(false); setSelectedWidget(item)}}
                                    className='widgetLink' >
                                    { item.replaceAll('-', ' ') }
                                </div>
                            ))
                        }
                    </div>
                ) : null
        }
        </div>
}

export default DemosDropDown;