import React from "react";
import Button from "../Button/Button";
import { EyeBrow } from "../StyledComponents/StyledComponents";

interface IProps {
    name: string,
    price: string,
    description: string[],
}

const PriceCard: React.FC<IProps> = ({name, price, description}) => {
    return (
        <div className="IR_priceCard">
            <div>
                <EyeBrow>{name}</EyeBrow>
                <h4>
                    {price} €<small style={{whiteSpace: 'pre'}}>/ MONTH</small>
                </h4>
                <ul>
                    {
                        description.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))
                    }
                </ul>
            </div>
            <Button url='/get-started' buttonType="secondary">Get started</Button>
        </div>

    );
}

export default PriceCard;