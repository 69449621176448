//This object should have all available options in the Widget suite.
//The have all undefined as value

const trademarkColor = '#4F5059';
const title_color = "#65666E";
const value_color = "#232530";
const line_color = "#4569EE";
const pos_price_color = "#038600";
const neg_price_color = "#DB0033";
const trading_open_color = "#038600";
const trading_closed_color = "#DB0033";
const table_line_color = "#E9E9EA";
const chart_line_color = "#E9E9EA";
const selector_text_color = "#65666E";
const selector_label_color = "#232530";
const x_axis_color = "#65666E";
const y_axis_color = "#65666E";
const hover_row_color = "#FCFDFF";
const hover_text_color = "#232530";
const area_color = "rgba(69, 105, 238, 0.4)";
const SYMBOL = "ARION";
const logoURL = "https://www.arionbanki.is/themes/arionbanki/arionbanki/images/05_Bankinn/Fleira/Myndabanki/Merki/ArionbankiRos.jpg"

export interface ISettings {
    // class: string,
    keldan?: boolean,
    trademark?: boolean,
    fullWidth?: boolean,
    logoOn?: boolean,
    shadow?: boolean,
    latest?: boolean,
    volume?: boolean,
    high?: boolean,
    low?: boolean,
    trades?: boolean,
    bid?: boolean,
    ask?: boolean,
    currency?: boolean,
    mcap?: boolean,
    one_m?: boolean,
    three_m?: boolean,
    one_y?: boolean,
    range?: boolean,
    borderSize?: string,
    borderColor?: string,

    border?: string,
    background?: string,
    transp_backgr?: boolean,
    font?: string,
    profileColor?: string,


    locale?: string,
    symbol?: string,

    //new controls
    //chart
    //colors
    textColor?: string,

    //toggles | checkboxes
    headerOn?: boolean,

    //not connected options
    //input fields
    //charts
    title?: string,
    stripped?: string,
    width?: string,
    chartBackground?: string,
    //stockLarge
    selectedOption?: string,

    //Colors 
    //share information
    headerColor?: string,

    //booleans, toggles or checkboxes
    //shareInformation
    market?: boolean,
    isin?: boolean,
    issuedShares?: boolean,
    ownShares?: boolean,
    utstandingShares?: boolean,
    outstandingShares?: boolean,
    change?: boolean,
    company?: boolean,

    //marketData
    industry?: boolean,

    //stockLarge
    open?: boolean,
    chartOptionOn?: boolean,
    volumeBarsOn?: boolean,
    chartScrollBarOn?: boolean,


    //financial summary
    // reportTypeOn?: boolean,
    // reportType?:string,
    // chartPeriodOn?: boolean,
    // timePeriod?: string,
    // annualPeriods?: string,
    // quarterlyPeriods?: string,

    //V2 !!!!!! 
    trademark_color?: string,
    title_color?: string,
    value_color?: string,
    trading_open_color?: string,
    trading_closed_color?: string,
    pos_price_color?: string,
    neg_price_color?: string,
    text_align?: string,
    auto_width?: boolean,

    min_year?: string,
    news_limit?: number | string,
    link_color?: string,
    date_color?: string,
    line_color?: string,
    hover_row_color?: string,
    hover_text_color?: string,
    hover_on?: boolean,
    logo_on?: boolean,
    logo?: string,
    month_12?: boolean,
    issued_shares?: boolean,
    outstanding_shares?: boolean,
    symbol_on?: boolean,
    own_shares?: boolean,
    items_per_column?: number | number,
    column_layout?: boolean,
    small?: boolean,
    chart_on?: boolean,
    header_color?: string,
    text_color?: string,
    primary_color?: string,
    secondary_color?: string,
    others_color?: string,
    selector_text_color?: string,
    selector_label_color?: string,


    y_axis_color?: string,
    x_axis_color?: string,
    legend_color?: string,
    default_chart?: string,
    default_period?: string,
    area_color?: string,
    volume_color?: string,
    header_on?: boolean,

    date?: boolean,
    close?: boolean,
    historical?: boolean,
    default_currency?: string,
    chart_line_color?: string,
    table_line_color?: string,
    currency_on?: boolean,
    max_limit?: string,
    icon_color?: string,

    seven_days?: boolean,
    netincomechart_on?: boolean,
    profitlosschart_on?: boolean,
    excel_download_button?: boolean,
    font_size?: string,
    no_wrap?: boolean,
    flex_gap?: string,
    dp_padding?: string,
    bold?: boolean,
}

/* all default options should be stored here */
const defaultSettings: ISettings = {
    locale: "en",
    symbol: SYMBOL,
    trademark: true,
    keldan: true,
    shadow: true,
    border: "2px solid transparent",
    transp_backgr: false,
    text_align: "left",
    font: "Roboto",
    auto_width: true,
    borderSize: "2",
    trademark_color: trademarkColor,
    background: "#ffffff",
    borderColor: "#FFF",
}

export const TopBannerV2_Options: ISettings = {
    ...defaultSettings,
    //checkboxes
    latest: true,
    volume: false,
    high: false,
    low: false,
    trades: true,
    bid: false,
    ask: false,
    currency: true,
    mcap: false,
    one_m: true,
    three_m: true,
    one_y: true,
    range: true,
    logo: logoURL,
    logo_on: true,

    //Colors
    title_color: "#65666E",
    value_color: "#232530",
    trading_open_color: pos_price_color,
    trading_closed_color: trading_closed_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
}

export const StockTickerV2_Options: ISettings = {
    ...defaultSettings,
    title_color: title_color,
    value_color: value_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
}


export const ShareBoxAV2_Options: ISettings = {
    ...defaultSettings,
    auto_width: undefined,
    logo: logoURL,
    //checkbox
    logo_on: true,
    latest: true,
    currency: true,
    one_m: true,
    three_m: true,

    //Colors
    title_color: title_color,
    value_color: value_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
    trading_open_color: trading_open_color,
    trading_closed_color: trading_closed_color,

}


export const ShareBoxBV2_Options: ISettings = {

    ...defaultSettings,
    auto_width: undefined,
    //checkbox

    latest: true,
    open: true,
    currency: true,
    trades: true,
    range: true,
    volume: true,

    //Colors
    title_color: title_color,
    value_color: value_color,
    line_color: chart_line_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
    trading_open_color: trading_open_color,
    trading_closed_color: trading_closed_color,

}

export const HistoricalPriceV2_Options: ISettings = {
    ...defaultSettings,
    auto_width: true,

    header_on: true,

    //toggles
    date: true,
    currency_on: true,
    open: true,
    high: true,
    low: true,
    close: true,
    volume: true,
    historical: true,

    //need to implement
    default_currency: "ISK",

    chart_line_color: chart_line_color,
    table_line_color: table_line_color,
    header_color: "#232530",
    value_color: "#232530",
    title_color: title_color,
    line_color: "#2235DF",
    area_color: "#4569ee",
    x_axis_color: x_axis_color,
    y_axis_color: y_axis_color,
    selector_text_color: selector_text_color,
    selector_label_color: selector_label_color,

    hover_on: true,
    hover_row_color: hover_row_color,
    hover_text_color: hover_text_color,
}

export const ShareHoldersLargeV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    symbol: "EIM",

    small: false,
    chart_on: true,

    line_color: chart_line_color,
    header_color: "#4F5059",
    text_color: "#232530",

    primary_color: "#6CD3FF",
    secondary_color: "#B07EF0",
    others_color: "#D3D3D6",
    excel_download_button: false,

    hover_on: true,
    hover_row_color: hover_row_color,
    hover_text_color: hover_text_color,
}
export const LatestNewsV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    // title: "Latest News",
    news_limit: "10",

    //hover
    hover_on: true,
    hover_text_color: hover_text_color,
    hover_row_color: hover_row_color,

    title_color: "#232530",
    link_color: "#1D3097",
    date_color: "#4F5059",
    line_color: chart_line_color,
    // hover_color: hover_color,
    // symbol: SYMBOL,
}
export const FinancialSummaryV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    // locale:"en",
    // symbol:SYMBOL,
    // trademark:true,
    // trademark_color: trademarkColor,
    // keldan:true,
    // shadow:true,
    // border:"2px solid transparent",
    // background:"#fff",
    // transp_backgr:false,
    // font:"'Roboto', sans-serif" ,


    line_color: chart_line_color,
    header_color: "#232530",
    min_year: (new Date().getFullYear() - 5).toString(),

    y_axis_color: y_axis_color,
    x_axis_color: x_axis_color,
    legend_color: "#4F5059",

    //have to implement these 2
    // default_chart:"Cash Flow",
    default_period: "Annual",
    selector_text_color: selector_text_color,
    selector_label_color: selector_label_color,

}


export const HistoricalCalculatorV2_Options: ISettings = {
    ...defaultSettings,

    value_color: value_color,
    title_color: title_color,

    default_currency: "ISK",
    header_color: value_color,
    chart_line_color: chart_line_color,
    table_line_color: table_line_color,
    line_color: line_color,
    area_color: area_color,
    x_axis_color: x_axis_color,
    y_axis_color: y_axis_color,
    neg_price_color: neg_price_color,
    pos_price_color: pos_price_color,
    selector_text_color: selector_text_color,
    selector_label_color: selector_label_color,

    hover_on: true,
    hover_row_color: hover_row_color,
    hover_text_color: hover_text_color,
}

export const ShareInformation_Options: ISettings = {
    // class: "symbol",
    symbol: SYMBOL,
    trademark: true,
    keldan: false,
    width: "auto",
    title: "Share Information",
    font: "'Roboto', sans-serif",
    shadow: true,
    border: "2px solid transparent",
    stripped: "inherit",
    background: "#ffffff",
    headerColor: "#000000",
    textColor: "#000000",
    pos_price_color: "green",
    neg_price_color: "red",
    market: true,
    isin: true,
    issuedShares: false,
    ownShares: false,
    outstandingShares: false,
    mcap: false,
    currency: true,
    range: true,
    one_m: true,
    three_m: false,
    one_y: true,
    latest: true,
    change: true,
    company: true,
    locale: "en",
    // symbol: SYMBOL,
}
export const MarketDataV2_Options: ISettings = {

    ...defaultSettings,

    value_color: value_color,
    title_color: title_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,

    line_color: table_line_color,

    market: true,
    isin: true,
    industry: true,
    issued_shares: true,
    own_shares: true,
    outstanding_shares: true,
    mcap: true,
    one_m: true,
    three_m: true,
    one_y: true,
    change: true,
    company: true,
    latest: true,
    currency: true,
    range: true,
    symbol_on: true,
    auto_width: false,

    // items_per_column: 2;
    items_per_column: 6,
    column_layout: false,

    // symbol: SYMBOL,

}

export const StockLargeV2_Options: ISettings = {
    ...defaultSettings,
    auto_width: undefined,
    //toggles
    //checkboxes
    latest: true,
    volume: true,
    high: true,
    low: true,
    trades: true,
    bid: true,
    ask: true,
    currency: true,
    mcap: true,
    one_m: true,
    three_m: true,
    one_y: true,
    range: true,
    // one_m: true,
    // three_m: true,
    // one_y: true,
    //colors
    title_color: title_color,
    value_color: value_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
    trading_open_color: trading_open_color,
    trading_closed_color: trading_closed_color,

    background: "#ffffff",
    // chartBackground: "transparent",
    line_color: '#4569EE',
    y_axis_color: '#4F5059',
    x_axis_color: '#4F5059',

    area_color: "rgba(69, 105, 238, 0.4)",
    volume_color: "#4569EE",

    // headerOn: false,
    header_on: true,
    selectedOption: "area",
    chartOptionOn: false,
    // volumeBarsOn: true,
    // chartScrollBarOn: false,
    // symbol: SYMBOL,

}

export const StockSmallV2_Options: ISettings = {
    ...defaultSettings,
    title_color: title_color,
    value_color: value_color,
    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
    background: "#ffffff",
    line_color: line_color,
    y_axis_color: y_axis_color,
    x_axis_color: x_axis_color,

    area_color: area_color,
    volume_color: "#65666E",

    header_on: true,
    //toggles
    //Colors
}

export const AnnualInterimAccountsV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    symbol: "ARION",

    line_color: chart_line_color,
    header_color: "#4F5059",
    text_color: "#232530",
    icon_color: '#DB0033',
    hover_on: true,
    hover_row_color: hover_row_color,
    hover_text_color: hover_text_color,
}

export const PerformanceV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    symbol: "ARION",

    line_color: chart_line_color,
    header_color: "#4F5059",
    text_color: "#232530",
    // hover_color: hover_color,

    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
    excel_download_button: false,

    hover_on: true,
    hover_row_color: hover_row_color,
    hover_text_color: hover_text_color,
}
export const LastTradesV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    symbol: "ARION",

    line_color: chart_line_color,
    header_color: "#4F5059",
    text_color: "#232530",
    // hover_color: hover_color,

    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
    max_limit: "10",
    excel_download_button: false,

    hover_on: true,
    hover_row_color: hover_row_color,
    hover_text_color: hover_text_color,
}

export const SmallStickerV2_Options: ISettings = {
    ...defaultSettings,
    keldan: undefined,
    trademark: undefined,
    text_align: undefined,
    auto_width: undefined,
    no_wrap: false,
    font_size: 'default',
    flex_gap: 'default',
    bold: false,
    dp_padding: 'default',
    //checkboxes


    //toggles
    latest: true,
    volume: true,
    trades: true,
    one_y: true,
    three_m: true,
    seven_days: true,
    currency: true,
    //Colors
    title_color: "#65666E",
    value_color: "#232530",

    pos_price_color: pos_price_color,
    neg_price_color: neg_price_color,
}

export const KeyFiguresV2_Options: ISettings = {
    ...defaultSettings,
    text_align: undefined,
    auto_width: undefined,
    // locale:"en",
    // symbol:SYMBOL,
    // trademark:true,
    // trademark_color: trademarkColor,
    // keldan:true,
    // shadow:true,
    // border:"2px solid transparent",
    // background:"#fff",
    // transp_backgr:false,
    // font:"'Roboto', sans-serif" ,


    line_color: chart_line_color,
    header_color: "#232530",
    min_year: (new Date().getFullYear() - 5).toString(),

    y_axis_color: y_axis_color,
    x_axis_color: x_axis_color,
    legend_color: "#4F5059",

    //have to implement these 2
    default_period: "Annual",
    selector_text_color: selector_text_color,
    selector_label_color: selector_label_color,

    netincomechart_on: true,
    profitlosschart_on: true,

}