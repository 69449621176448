import React from 'react'
import cx from 'classnames';

import mobileNormalLogo from "../../../Assets/Images/mobile-normal-logo.svg";
import normalLogo from "../../../Assets/Images/normalLogo.svg";
import whiteLogo from "../../../Assets/Images/white-logo.svg";
import whiteMobileLogo from "../../../Assets/Images/whiteMobileLogo.svg";

interface IProps {
    mobile: boolean,
    white?: boolean
}
const Logo: React.FC<IProps> = ({mobile, white}) => {
  return <div className={cx("IR_headerLogo", {'mobile': mobile, 'white': white})}>
    {/* mobile logo */}
    {/* bigger mobile logo */}
    <img
        src={whiteLogo}
        alt="logo"
        className={cx("mobile-big-logo")}
    />
    {/* smaller mobile logo */}
    <img
        src={whiteMobileLogo}
        alt="logo"
        className={cx("mobile-small-logo")}
    />
    {/* normal logo */}
    {/* bigger normal logo */}
    <img
        src={normalLogo}
        alt="logo"
        className={cx("normal-big-logo")}
    />
    {/* smaller normal logo */}
    <img
        src={mobileNormalLogo}
        alt="logo"
        className={cx("normal-small-logo")}
    />
  </div>
}

export default Logo
