import React, { useEffect } from 'react';
import { Routes, Route, useLocation} from "react-router-dom";
import { AuthProvider, RequireAuth } from 'react-auth-kit';
import { IntlProvider } from 'react-intl';
import AppLocale from './lngProvider';
import Privacy from './Pages/Home/Privacy/Privacy';
import CustomSolution from './Pages/Home/Custom/Custom';

//pages imports:
import HomePage from "./Pages/Home/HomePage/HomePage";
import OurWidgets from "./Pages/Home/OurWidgets/OurWidgets";
import Pricing from "./Pages/Home/Pricing/Pricing";
import AboutUs from "./Pages/Home/AboutUs/AboutUs";
import Contact from "./Pages/Home/Contact/Contact";
import GetSarted from "./Pages/Home/GetStarted/GetStarted";
import Demos from './Pages/Home/Demos/Demos';
import TermsPage from './Pages/Home/Terms/Terms';
import Login from './Pages/Login';
import ErrorPage from './Pages/Error/NotFound';
import WidgetsV2 from './Pages/Authorized/IrWidgets';
import IrSingleWidget from './Pages/Authorized/IrSingleWidget';
import ReactGA from 'react-ga4';


const App:React.FC = () => {
  const currentAppLocale = AppLocale["en"];
  const location = useLocation();
  ReactGA.initialize('G-0WESY4B1VW');
  useEffect(() => {
    //non interaction
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.search });

  }, [location])

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  return (
    <AuthProvider
      authType={'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Login" element={ <Login /> } />

          {/* UNAUTHORIZED ROUTES */}
          <Route path="/Demos" element={<Demos />} />
          <Route path="our-widgets" element={<OurWidgets />} />  
          <Route path="pricing" element={<Pricing />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="get-started" element={<GetSarted />} />
          <Route path='custom-solution' element={<CustomSolution /> }/>
          <Route path='terms' element={<TermsPage /> }/>
          <Route path='privacy' element={<Privacy /> }/>

          {/* AUTHORIZED ROUTES */}
          <Route path="all-ir-widgets" element={
            <RequireAuth loginPath={'/login'}>
              <WidgetsV2 />
            </RequireAuth>}
          />
          <Route path='all-ir-widgets/:id' element={
            <RequireAuth loginPath={'/login'}>
              <IrSingleWidget />
            </RequireAuth>}
          />

          {/* ERROR ROUTES */}
          <Route path="/*" element={<ErrorPage errorCode={404}/>} />
        </Routes>
      </IntlProvider>
    </AuthProvider>
  );
}

export default App;