import React, { useState, useEffect, useRef } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Reaptcha from "reaptcha";

import Header from "../../../Components/Ui-elements/Header";
import Footer from "../../../Components/Ui-elements/Footer";
import Input from "../../../Components/Ui-elements/Input/Input";
import Alert from "../../../Components/Ui-elements/Alert/Alert";
import Button from "../../../Components/Ui-elements/Button/Button";
import HeadSection from "../../../Components/Ui-elements/HeadSection/HeadSection";
import { FormModal, Page, ShellCentered } from "../../../Components/Ui-elements/StyledComponents/StyledComponents";
import { TOAST_DELAY } from "../../../Utils/func";

const GetStarted: React.FC = () => {
  const form = useRef(null);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [symbol, setSymbol] = useState("");
  const [website, setWebsite] = useState("");
  const [questions, setQuestions] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [contactResponse, setContactResponse] = useState<JSX.Element>(<></>);
  const [showToast, setShowToast] = useState(false);

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const verify = () =>{
    //@ts-ignore
    captchaRef.current.getResponse().then(res => {
        setCaptchaToken(res)
    })
  }
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
        const url = `https://keldan.is/IrPage/SendEmail?type=Get-started&name=${name}&company=${company}&phone=${phone}&email=${email}&ticker=${symbol}&website=${website}&body=${questions}&captchaToken=${captchaToken}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            "Access-Control-Allow-Origin": "*"
          },
        });
        if (response.ok) {
            const responseBody: {success: boolean, message: string} = await response.json();
            if (responseBody.success) {
                setContactResponse(<Alert type={"success"} headText={"Email was sent"} />)
                setShowToast(true)
                setName("")
                setCompany("")
                setPhone("")
                setEmail("")
                setSymbol("")
                setWebsite("")
                setQuestions("")
            } else {
              setContactResponse(<Alert type={"error"} headText={"Email was not able to send"} />)
              setShowToast(true)
            }
        } else {
          setContactResponse(<Alert type={"error"} headText={"Request failed"} />)
          setShowToast(true)
        }
    } catch (e) {
        setContactResponse(<Alert type={"error"} headText={"Network error"} />)
        setShowToast(true)
        console.log(e)
    }
  };
  
  return (
    <div className="IR_getStarted">
      <Header scroll={true} />
      <Page>
        <ShellCentered>
          <HeadSection
            title={"get started"}
            question={"Tell us your company information"}
            description={"Just fill out the form and we will send you a full-featured demo account."}/>
          <div className="formContainer">
          <FormModal>
              <form ref={form} className="getStarted-form" onSubmit={handleFormSubmit}>
                {/* <div className="row"> */}
                  <div className="inputWrapper">
                    <Input
                      required={true}
                      type={"text"}
                      name={"fullName"}
                      id={"fullname"}
                      label={"Full name"}
                      value={name}
                      onChange={(a: string) => setName(a)} />
                    <Input
                      required={true}
                      type={"text"}
                      name={"company"}
                      id={"company"}
                      label={"Company"}
                      value={company}
                      onChange={(a: string) => setCompany(a)} />
                  </div>
                  <div className="inputWrapper">
                    <Input
                      required={true}
                      type={"text"}
                      name={"phone"}
                      id={"phone"}
                      label={"Phone number"}
                      value={phone}
                      onChange={(a: string) => setPhone(a)} />
                    <Input
                      required={true}
                      type={"email"}
                      name={"email"}
                      id={"email"}
                      label={"Email"}
                      value={email}
                      onChange={(a: string) => setEmail(a)} />
                  </div>
                  <div className="inputWrapper">
                    <Input
                      required={true}
                      type={"text"}
                      name={"symbol"}
                      id={"symbol"}
                      label={"Company Stock Symbol (Ticker)"}
                      value={symbol}
                      onChange={(a: string) => setSymbol(a)} />
                    <Input
                      required={true}
                      type={"text"}
                      name={"website"}
                      id={"website"}
                      label={"Website url"}
                      value={website}
                      onChange={(a: string) => setWebsite(a)} />
                  </div>
                  <Input
                    required={true}
                    type={"textarea"}
                    name={"questions"}
                    id={"questions"}
                    label={"Any questions? Or tell us what you are looking for to include in you're IR page"}
                    value={questions}
                    onChange={(a: string) => setQuestions(a)} />
                  <div style={{marginTop: '25px'}} className="jc-center">
                    <Reaptcha
                      className="g-recaptcha"
                      sitekey={ '6LcamJokAAAAADXBk0plogjoCBuIdFcE3UWa9dvc'}
                      ref={captchaRef}
                      onVerify={verify}
                      isolated
                    ></Reaptcha>
                  </div>
                  <div style={{'marginTop': '30px'}}className="text-center jc-center">
                    <Button type="submit" buttonType="submit" size="lg">Submit</Button>
                  </div>
                {/* </div> */}
              </form>
          </FormModal>
          </div>
          <div className="nasdaqOnly">
            <div className="iconWrapper">
              <FontAwesomeIcon icon={faInfoCircle} className="fa-icon" />
            </div>
            <p>
              We currently offer the IR Widget Builder only to companies who
              are listed on the Nasdaq Nordic.
            </p>
          </div>
        </ShellCentered>
      </Page>
      <Footer/>
      <ToastContainer className='toastContainer' >
          <Toast onClose={() => setShowToast(false)} autohide delay={TOAST_DELAY} show={showToast}>
              {contactResponse}
          </Toast>
      </ToastContainer>
    </div>
  );
}

export default GetStarted;