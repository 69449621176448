// Package imports:
import React from 'react';
import cx from 'classnames';
// // Component imports:
import Button from '../Button/Button'
import { EyeBrow } from '../StyledComponents/StyledComponents';

// Service imports:
// Type imports:
interface IProps {
    info: string,
    title: string | JSX.Element,
    description?: JSX.Element,
    image?: string | JSX.Element,
    button?: {title: string, url: string, target?: string},
    reverse?: boolean,
    width?: string;
}
function isElement(element: JSX.Element | string) {
    return React.isValidElement(element);
}

const ParagraphWithImage: React.FC<IProps> = ({
    info,
    title,
    description,
    image,
    button,
    reverse,
    width,
    ...props
}) => {
    
    return <div style={{width: width}} className={cx('IR_paragraphWithImage',{ 'reverse': reverse })}>
            <div className='title-description-wrapper'>
                <EyeBrow className='info'>{info}</EyeBrow>
                <h3 className='title'>{title}</h3>
                {description ? <span className='description'>{description}</span> : null}
                {
                    button
                    ? (
                        <Button buttonType={'secondary'} url={button.url} target={button.target}>{button.title}</Button>
                    ) : null
                }
            </div>
            {
                
                image ? 
                isElement(image)
                    ? image
                    : (
                        <div className='image'>
                            {/* @ts-ignore */}
                            <img src={image} alt="document" />
                        </div>
                    )
                : null
            }
        </div>
}

export default ParagraphWithImage;