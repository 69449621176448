import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

//components imports:
import Header from '../../../Components/Ui-elements/Header';
import { Page, Shell } from '../../../Components/Ui-elements/StyledComponents/StyledComponents';
import HeadSection from '../../../Components/Ui-elements/HeadSection/HeadSection';
import ActiveButton from '../../../Components/Ui-elements/ActiveButton/ActiveButton';
import Button from '../../../Components/Ui-elements/Button/Button';
import Footer from '../../../Components/Ui-elements/Footer';

import { IUserInfo } from '../../../Types/GeneralTypes';

import { ISettings } from "../../Authorized/WidgetOptions";
import DemosDropDown from '../../../Components/Ui-elements/DropDown/DemosDropDown';
import { getInitialSettings, sendGoogleAnalyticCustomEvent } from '../../../Utils/func';


const widgetList = [
  "annual-interim-accounts",
  "calculator",
  "financial-summary",
  "historical-price",
  "latest-news",
  "last-trades",
  "key-figures",
  "market-data",
  "performance",
  "share-box-a",
  "share-box-b",
  "shareholders-large",
  "shareholders-small",
  "small-sticker",
  "stock-large",
  "stock-small",
  "stock-ticker",
  "top-banner",
];

const Demos: React.FC<IUserInfo> = ({
  isAuthenticated
}) => {
  const [activeWidget, setActiveWidget] = React.useState<string>(widgetList[14])
  const [settings, setSettings] = React.useState<ISettings>({ ...getInitialSettings(activeWidget), ...{ symbol: 'ARION' } });

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  useEffect(() => {
    window.scrollTo(0, 0);

    //send google analytic event
    // sendGoogleAnalyticCustomEvent('Demos', 'widget_changed', 'Demos page', 'value')
  }, []);

  useEffect(() => {
    //send google analytic event
    sendGoogleAnalyticCustomEvent('Demos', 'widget_changed', 'activewidget: ' + activeWidget)
  }, [activeWidget])
  useEffect(() => {
    setSettings(getInitialSettings(activeWidget))
  }, [activeWidget])
  
  const displayWidget = (widget: string | undefined) => {
    switch (widget) {
      case 'share-box-a': return <share-box-a-v2 {...settings} />
      case 'share-box-b': return <share-box-b-v2 {...settings} />
      case 'historical-price': return <historical-price-v2 {...settings} />
      case 'shareholders-large': return <shareholders-large-v2 {...settings} />
      case 'shareholders-small': return <shareholders-large-v2 {...settings} small={true} />
      case 'latest-news': return <latest-news-v2  {...settings} locale="en" />
      case 'financial-summary': return <financial-summary-v2 {...settings} />
      case 'calculator': return <calculator-v2 {...settings} />
      case 'share-information': return <share-information {...settings} />
      case 'market-data': return <market-data-v2 {...settings} />
      case 'stock-large': return <stock-large-v2 {...settings} />
      case 'stock-small': return <stock-small-v2 {...settings} />
      case 'stock-ticker': return <stock-ticker-v2 {...settings} />
      case 'top-banner': return <top-banner-v2 {...settings} />
      case 'annual-interim-accounts': return <annual-interim-accounts-v2 {...settings} />
      case 'last-trades': return <last-trades-v2 {...settings} />
      case 'key-figures': return <key-figures-v2 {...settings} />
      case 'performance': return <performance-v2 {...settings} />
      case 'small-sticker': return <small-sticker-v2 {...settings} />
      default: return null;
    }
  };

  const overideContainerStyle = (activeWidget === "stock-small")
    ? { minWidth: '700px' }
    : (activeWidget === "financial-summary")
      ? { minWidth: '900px' }
      : {}

  return (
    <div className='IR_demos'>
      <Header scroll={true} />
      <Page>
        <Shell>
          <HeadSection
            title="Demos"
            question="Live Market Data IR widgets"
            theme='greenAndPurple'
          />
          <div className='widgetButtons'>
            {
              widgetList.map((item, index) => (
                <div key={index} className='singleButton'>
                  <ActiveButton
                    active={activeWidget === item}
                    onClick={() => { setActiveWidget(item); setSettings({ ...settings, ...{ title: capitalizeFirstLetter(item).replaceAll('-', ' ') } }) }}>
                    {item.replaceAll('-', ' ')}
                  </ActiveButton>
                </div>
              ))
            }
          </div>
          <div className='dropDownWrapper'>
            <DemosDropDown
              selectedWidget={activeWidget}
              setSelectedWidget={(widget: string) => {
                setActiveWidget(widget);
                setSettings({ ...settings, ...{ title: capitalizeFirstLetter(widget).replaceAll('-', ' ') } })
              }}
              title={widgetList} />
          </div>
          {/* disclaimer */}
          <div className='jc-center'>
            <div className='demoDisclaimer'>
              <div style={{ display: 'flex', height: '100%' }}>
                <FontAwesomeIcon style={{ width: '30px', height: '25px', top: '-30px' }} className='fa-icon' icon={faInfoCircle} />
                <span className='disclaimerDescription'>
                  The Financial Summary widget below might be using sample data. Please login to your account to view your data or contact us if your data is not connected yet.
                </span>
              </div>
              <Button
                buttonType="submit"
                size="lg"
                url='/contact'>
                Contact us
              </Button>
            </div>
          </div>
          <div className='jc-center widgetWrapper'>
            <div style={overideContainerStyle}>
              {displayWidget(activeWidget)}
            </div>
          </div>
        </Shell>
      </Page>
      <Footer />
    </div>
  )
}

export default Demos;