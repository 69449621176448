import React, { useEffect } from "react";

import Header from "../../../Components/Ui-elements/Header";
import Footer from "../../../Components/Ui-elements/Footer";
import PriceCard from "../../../Components/Ui-elements/PriceCard/PriceCard";
import HeadSection from "../../../Components/Ui-elements/HeadSection/HeadSection";
import SmallPrint from "../../../Components/Ui-elements/SmallPrint/SmallPrint";
// import FiancialReports from "../Assets/Images/financial-reports.svg";

import FinancialReports from "../../../Assets/Images/financial-reports.svg";
import SmallPrintas from "../../../Assets/Images/small-print.svg";
import { Page, ShellCentered } from "../../../Components/Ui-elements/StyledComponents/StyledComponents";
import { IUserInfo } from "../../../Types/GeneralTypes";
interface ICard {
  name: string,
  price: string,
  description: string[],
}

const priceCardInfo: ICard[] = [
  {
    name: 'FIRST NORTH',
    price: '150',
    description: ['All widgets', 'No setup fee', 'No long term contracts']
  },   {
    name: 'SMALL CAP',
    price: '250',
    description: ['All widgets', 'No setup fee', 'No long term contracts', 'Custom services']
  }, {
    name: 'MID & LARGE CAP',
    price: '350',
    description: ['All widgets', 'No setup fee', 'No long term contracts', 'Custom services']
  },
]

const Pricing:React.FC<IUserInfo> = ({
  isAuthenticated
}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="IR_pricingPage">
      <Header scroll={true}/>
      <Page>
        <ShellCentered>
          <HeadSection
            title={"pricing"}
            question={"Try for free and pay later"}/>
          <div className="priceCardWrapper">
            {
              priceCardInfo.map((item, index) => (
                <PriceCard key={index} name={item.name} price={item.price} description={item.description} />
              ))
            }
          </div>
          <div className="smallPrintContainer">
            <div className="smallPrintWrapper">
              <div className="smallPrint">
                <SmallPrint
                  source={FinancialReports}
                  title={"Financial, Shareholder & Reports"}
                  description={<p>To have the financial, shareholder, and reporting widgets up to date, there is an extra €49/monthly fee.</p>}
                  mobileWrapStyle={'top'} />
              </div>
              <div className="smallPrint">
                <SmallPrint
                  source={SmallPrintas}
                  title={"The small print"}
                  description={<p>
                    Flat rate monthly fee based on the market capitalization of
                    the listed company. <br/>
                    Fees quoted per instrument. Discounts
                    available for dual-listed or multi-instrument companies.<br/>
                    Add 50% for use in multiple domains.
                  </p>}
                  mobileWrapStyle={'top'}
                  />
              </div>
            </div>
          </div>
        </ShellCentered>
      </Page>
      <Footer/>
    </div>
  );
}

export default Pricing;
