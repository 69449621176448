import React from "react";
import cx from 'classnames';

import normalLogo from "../../Assets/Images/normalLogo.svg";
import phone from "../../Assets/Images/phone-icon.svg";
import email from "../../Assets/Images/email-icon.svg";
import Button from './Button/Button';
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

function format(date12: Date) {
  const date = new Date(date12);

  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return "1." + year + "" + month + "" + day;
}

interface IProps {
  onlyDisplaySecondFooter?: boolean}

const Footer: React.FC<IProps> = ({
  onlyDisplaySecondFooter
}) => {
  const version = process.env.REACT_APP_VERSION || format(new Date());

  const authenticated = useIsAuthenticated();

  return (
    <footer className="footer">
    {(!onlyDisplaySecondFooter) &&
      <div className="primaryFooter">
        <div className="primaryFooter-content">
          <div className="contactInfo-container">
            <img src={normalLogo} alt="logo-footer" className="logo" />
            <Link className="address" to="https://www.google.com/maps/place/K%C3%B3%C3%B0i+ehf/@64.1466733,-21.9017443,17z/data=!3m1!4b1!4m5!3m4!1s0x48d674c422e55555:0xd44ff2a07262f3df!8m2!3d64.1466477!4d-21.8995462">
              Kóði ehf., Borgartún 25, 105 Reykjavík, Iceland
            </Link>

            <p className="phone-email">
              <span className="phone">
                <img src={phone} alt="phone" />
                <a href="tel:+3545622800" className="phoneNumber">+354 562 2800</a>
              </span>
              <span className="email">
                <img src={email} alt="email" />
                <a className="mailLink" href="mailto:sales@livemarketdata.com">sales@livemarketdata.com</a>
              </span>
            </p>
          </div>
          <div className="linksAndLoginWrapper">
            <div className="linkContainer">
              <div className="linkWrapper">
                  <Link to="/our-widgets">IR widgets</Link>
                  <Link to="/pricing">Pricing</Link>
                  <Link to="/demos">Demos</Link>
              </div>
              <div className="linkWrapper">
                  <Link to="/about-us">About us</Link>
                  <Link to="/contact">Contact</Link>
                  <Link to="/get-started">Get started</Link>
              </div>
            </div>
              {
                !authenticated()
                ? <div className="loginButtonContainer">
                    <Button buttonType="primary" url= '/login' icon={faLock}>Login</Button>
                  </div>
                : null
              }
          </div>
        </div>
      </div>
      }
      <div className={`secondaryFooter secondary${authenticated() ? " authenticated" : ""}`}>
        <div style={{'alignItems': (authenticated()) ? 'flex-end' : 'flex-start'}} className="secondaryFooter-container">
          <div className="left">
            <p>
              LiveMarketData.com © 2022 Kóði ehf. <br /> Investor Relations & Data Cloud
            </p>
            {
              authenticated() &&
              <>
                <span className="prik">|</span>
                <Link to={"/custom-solution"}>Need a custom solution?</Link>
              </>
            }
          </div>
          <div className={cx("right")}>
            {authenticated() ? (
              <p className="version">Version: {version}</p>
            ) : (
              <>
                <Link className="terms" to="/terms">
                  Terms
                </Link>
                <Link className="privacy" to="/privacy">
                  Privacy
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </footer>


  );
}
export default Footer;