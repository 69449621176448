//package imports: 
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Toggle from 'react-toggle'

//Component imports:
import Header from "../../Components/Ui-elements/Header";
import Footer from "../../Components/Ui-elements/Footer";
import { Page, Shell } from "../../Components/Ui-elements/StyledComponents/StyledComponents";

//image imports:
import boxIcon from "../../Assets/Images/box-icon.svg";
import listIcon from "../../Assets/Images/List-icon.svg";

//widget images imports:
import annual_initerim_accounts from "../../Assets/Images/Widgets/annual-interim-accounts.svg";
import calculator from "../../Assets/Images/Widgets/calculator.svg";
import financial_summary from "../../Assets/Images/Widgets/financial-summary.svg";
import historical_price from "../../Assets/Images/Widgets/historical-price.svg";
import latest_news from "../../Assets/Images/Widgets/latest-news.svg";
import last_trades from "../../Assets/Images/Widgets/last-trades.svg";
import market_data from "../../Assets/Images/Widgets/market-data.svg";
import performance from "../../Assets/Images/Widgets/performance.svg";
import share_box_a from "../../Assets/Images/Widgets/share-box-a.svg";
import share_box_b from "../../Assets/Images/Widgets/share-box-b.svg";
import shareholders_large from "../../Assets/Images/Widgets/shareholders-large.svg";
import small_sticker from "../../Assets/Images/Widgets/small-sticker.svg";
import stock_large from "../../Assets/Images/Widgets/stock-large.svg";
import key_figures from "../../Assets/Images/Widgets/key-figures.svg";
import stock_small from "../../Assets/Images/Widgets/stock-small.svg";
import stock_ticker from "../../Assets/Images/Widgets/stock-ticker.svg";
import custom_solution from "../../Assets/Images/Widgets/Frame.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/pro-regular-svg-icons";

interface IWidgetCardData {
  title: string, 
  description: string,
  image: string,
}

const WidgetsV2:React.FC = () => {
  const [listView, setListView] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const widgetList: IWidgetCardData[] = [
    {
      title: "annual-interim-accounts",
      description: "Table with downloadable pdf document.",
      image: annual_initerim_accounts
    },
    {
      title: "calculator",
      description: "Choose your dates and calculate amount invested or shares bought. ",
      image: calculator
    },
    // {
    //   title: "finance-charts",
    //   description:"3 charts showing total income, ernings and ernings per share.",
    //   image: financeChart
    // },
    // {
    //   title: "financial-information",
    //   description: "Table showing income, balance and cash flow.",
    //   image: financial_information,
    // },
    {
      title: "financial-summary",
      description: "Bar chart showing the financial summary.",
      image: financial_summary,
    },
    {
      title: "historical-price",
      description: "Historical Price shown with line chart.",
      image: historical_price,
    },
    {
      title: "key-figures",
      description: "Bar chart showing key figures both annualy and quarterly. ",
      image: key_figures,
    },
    {
      title: "latest-news",
      description:  "Latest news shown in a list with date. Choose from showing 5 to 100 news.",
      image: latest_news,
    },
    {
      title: "last-trades",
      description: "Table showing last trades. Choose from showing 5 to 100 last trades.",
      image: last_trades,
      },
    {
      title: "market-data",
      description: "Market data shown in a list. Choose from a few statistics to display.",
      image: market_data,
      },
    {
      title: "performance",
      description: "Table showing the performance from yesterday up to a whole year. ",
      image: performance,
      },
    // {
    //   title: "price-data",
    //   description: "Tables showing price data. Choose from a few statistics to display.",
    //   image: price_data,
    //   },
    {
      title: "share-box-a",
      description: "Box showing share information with company logo and trading status.",
      image: share_box_a,
    },
    {
      title: "share-box-b",
      description: "Compressed box showing share information without company logo.",
      image: share_box_b,
      },
    {
      title: "shareholders-large",
      description: "Table with 20 largest shareholders and infograph with 5 largest. ",
      image: shareholders_large,
      },
    // {
    //   title: "shareholders-small",
    //   description: "Table and infograp with 5 largest shareholders. ",
    //   image: shareholders_small,
    // },
    // {
    //   title: "share-snapshot",
    //   description: "Small box showing current time, share information without company logo.",
    //   image: share_snapshot,
    // },
    {
      title: "small-sticker",
      description: "Horizontal line with share infomation. Choose from a few statistics to display.",
      image: small_sticker,
      },
    {
      title: "stock-large",
      description: "Line chart showing stock information.  Choose from a few statistics to display.",
      image: stock_large,
      },
    {
      title: "stock-small",
      description: "Small line chart with stock and last trade information.",
      image: stock_small,
    },
    {
      title: "stock-ticker",
      description: "Small card with ticker information.",
      image: stock_ticker,
    },
    {
      title: "top-banner",
      description: "Horizontally choose statistics to display from share and ticker data .",
      image: small_sticker,
    },
    // {
    //   title: "traffic-data",
    //   description: "Bar chart showing air traffic data for past 5 years.",
    //   image: traffic_data,
    // },




    // {
    //   title: "ir-page-1",
    //   description: "",
    // },
    // {
    //   title: "ir-page-2",
    //   description: "",
    // },
    // {
    //   title: "ir-page-3",
    //   description: "",
    // },
    // {
    //   title: "ir-page-4",
    //   description: "",
    // },
    // {
    //   title: "ir-page-5",
    //   description: "",
    // },
    // {
    //   title: "ir-page-6",
    //   description: "",
    // },
    {
      title: 'Need a custom solution?',
      description: 'You can contact us and tell us your preferences.',
      image: custom_solution,
    }
  ];
  const displayListViewHeaders = () => {
    return <div className="headerTitles">
              <span className="widgetNameHeader">Widget name</span>
              <span className="widgetDescriptionHeader">Description</span>
            </div>
  }
  const displayListView = () => {
    return widgetList.map((item, ind) => (
        <Link key={ind} className="box" to={(item.title === 'Need a custom solution?') ? '/custom-solution' : "/all-ir-widgets/" + item.title}>
          <span className="widgetName">{item.title.replaceAll('-', ' ')}</span>
          <span className="widgetDescription">{item.description}</span>
        </Link>
    ))
  }

  const displayCardView = () => {
    return widgetList.map((item, ind) => (
      <Link key={ind} className="card" to={(item.title === 'Need a custom solution?') ? '/custom-solution' : "/all-ir-widgets/" + item.title}>
        <div className="titleWrapper">
          
          <span className="widgetName"><FontAwesomeIcon className="fa-icon" icon={faCircleArrowRight} />{item.title.replaceAll('-', ' ')}</span>
          <span className="widgetDescription">{item.description}</span>
        </div>
        <div className="imageContainer">
          <img
              alt="widget"
              draggable={false}
              src={item.image}
            />
        </div>
      </Link>
    ))
  }
  return (
    <div id="main" className="IR_irWidgets">
      <div className="container">
        <Header authenticatedPage />
        <Page>
          <Shell className="noSidePadding">
            <div className="toggleWrapper">
              <label className="viewType">
                <Toggle
                  defaultChecked={listView}
                  icons={{
                    checked: <img className="toggleIcons" src={boxIcon} alt="boxIcon"/>,
                    unchecked: <img className="toggleIcons" src={listIcon} alt="listIcon"/>,
                  }}
                  onChange={() => setListView(!listView)} />
              </label>
              <span className="allwidgets">All widgets from A - Z</span>
            </div>
          {
            (listView) ? displayListViewHeaders() : null
          }
          {
            listView ? (<div className="contentWrapper">{displayListView()}</div>) : null
          }
          {
            !listView ? (<div className="cardViewWrapper"><div className="cardViewContainer">{displayCardView()}</div></div>) : null
          }
          </Shell>
        </Page>
        </div>
        <Footer onlyDisplaySecondFooter/>
      </div>
  );
}

export default WidgetsV2;