import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

interface NumberInputWithCustomButtonsProps {
  value: number;
  disabled?: boolean;
  min: number;
  max: number;
  placeholder?: string;
  onChange: (newValue: string) => void;
  width?: string,
  label?: string,
}

const NumberInput: React.FC<NumberInputWithCustomButtonsProps> = ({
  value,
  disabled,
  min,
  max,
  placeholder,
  onChange,
  width,
  label
}) => {
  const [number, setNumber] = useState<number>(value);

  const incrementNumber = () => {
    if (!disabled && number < max) {
      const newNumber = number + 1;
      setNumber(newNumber);
      onChange(newNumber.toString());
    }
  };

  const decrementNumber = () => {
    if (!disabled && number > min) {
      const newNumber = number - 1;
      setNumber(newNumber);
      onChange(newNumber.toString());
    }
  };

  const displayValue = number === 0 ? placeholder ?? '' : number;

  return (
      <div className={cx('IR_numberInput', {'disabled': disabled})}>
        {label && <label>{label}</label>}
      <input
        style={{width: width}}
        type="text"
        placeholder={placeholder ?? '0'}
        value={displayValue}
        onChange={(e) => {
          const inputNumber = parseInt(e.target.value, 10);
          if (inputNumber === 0 || !isNaN(inputNumber) && inputNumber >= min && inputNumber <= max) {
            setNumber(inputNumber);
            onChange(inputNumber.toString());
          }
        }}
        disabled={disabled}
      />

      <div className="iconWrapper">
        <FontAwesomeIcon className="faIcon" onClick={incrementNumber} icon={faAngleUp} />
        <FontAwesomeIcon className="faIcon" onClick={decrementNumber} icon={faAngleDown} />
      </div>
    </div>
  );
};

export default NumberInput;