import styled from 'styled-components';

export const Page = styled.div`
    width: 100%;
    height: 100%;
`;

export const Shell = styled.div`
    max-width: 1366px;
    padding: 150px 37px 0;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 640px){
        padding: 97px 15px 60px;
    }
`;

export const ShellBody = styled.div`
    max-width: 1366px;
    padding: 144px 37px 100px;
    margin-left: auto;
    margin-right: auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    @media (max-width: 640px){
        padding: 60px 15px
    }
`;

export const ShellCentered = styled.div`
    max-width: 1366px;
    padding: 150px 37px 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 640px){
        padding: 97px 15px 60px;
    }
`;

export const Row = styled.div`
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
`;

export const H1 = styled.h1<{
    alignment?: 'left' | 'center' | 'right',
    halfWidth?: boolean}>`
    text-align:${c => c.alignment ?? 'left'};
    width:${c => c.halfWidth ? '670px' : 'auto'};
    font-family: 'Poppins';
    font-style: normal;
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;

    @media (max-width: 640px){
        font-size: 34px;
        line-height: 40px;
        width: auto;
    }
`;

export const H5 = styled.h5<{
    alignment?: 'left' | 'center' | 'right'}>`
    text-align:${c => c.alignment ?? 'left'};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    text-transform: uppercase;
    @media (max-width: 640px){
        font-size: 14px;
        line-height: 21px;
    }
`;

export const FormModal = styled.div`
    padding: 50px 115px 40px;
    background: white;
    border-radius: 20px;
    display: flex;
    width: 100%;
    max-width: 672px;
    justify-content: center;

    > form {width: 100%;}
    
    
    @media(max-width: 640px){
        padding: 40px 15px;
    }
`;

export const EyeBrowBeforeH1 = styled.h5<{
    alignment?: 'left' | 'center' | 'right'}>`
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    @media(max-width: 640px){
        font-size: 14px;
        line-height: 24px;
    }
`;

export const EyeBrow = styled.h5`
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom 6px;
    color: #209AA0;
    
    @media(max-width: 640px){
        margin-bottom:10px;
        font-size: 12px;
        line-height: 18px;
    }
`;


