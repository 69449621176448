import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

interface ICheckbox {
    checked: boolean,
    label: string,
    disabled?: boolean,
    className?: 'rememberMe',
}
const Checkbox: React.FC<ICheckbox> = ({
    checked,
    label,
    disabled,
    className
}) => {
    return <div className={cx('IR_checkbox', className, {'checked': checked, 'disabled': disabled})}>
        <div className='box'>
            {
                checked 
                ? <FontAwesomeIcon className='fa-icon' icon={faCheck} />
                : null
            }
        </div>
        <label>{label}</label>
    </div>
}
export default Checkbox;