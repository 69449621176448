import React from 'react';
import { SketchPicker } from 'react-color';
import cx from 'classnames';
import useComponentVisible from '../../../Hooks/ComponentVisible';

interface IColorPicker {
    value: string,
    label: string,
    onChange: (e: string) => void,
    disabled: boolean
}
const ColorPicker:React.FC<IColorPicker> = ({
    value,
    label,
    onChange,
    disabled
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [color, setColor] = React.useState(value)
    
    return <div className={cx('IR_colorPicker', {'disabled': disabled})} ref={ref}>
            <label>{label}</label>
            <div 
                className="colorPickerBtn"
                onClick={() => !disabled ? setIsComponentVisible(!isComponentVisible) : null}>
                <div style={{background: color}} className="selectedColor"></div>
                <span className="text">Edit</span>
            </div>
            <div className="colorPickerModule_wrapper">
            {
                isComponentVisible 
                ? <SketchPicker
                   color={ color}
                   onChange={(e) => setColor(e.hex)}
                   onChangeComplete={(e) => onChange(e.hex)}/>
                : null
            }
            </div>
    </div>
}

export default ColorPicker;