// Package imports:
import React from 'react'

import Cookies from 'universal-cookie';
import Button from '../Button/Button';

const CookieConsentForm: React.FC = () => {
    //creating context
    const cookies = new Cookies();
    // if cookie is not found display component
    const [ visible, setVisible ] = React.useState(!cookies.get('_IR-WidgetCookieConsent'));
    
    const handleClick = () => {
        let today = new Date();
        cookies.set('_IR-WidgetCookieConsent', 1, {expires: new Date(today.setMonth(today.getMonth() + 6)), path: '/' });
        setVisible(false)
    }

    if (visible){
        return (
            <div className="IR_cookieConsentForm">
                <div className='textContainer'>
                    <span>
                        We use cookies to improve your experience on our website.<br/>
                        By browsing this website, you agree to our use of cookies.
                    </span>
                </div>
                    <Button
                        buttonType='primary'
                        size='md'
                        onClick={() => handleClick()}
                        >
                            Accept
                    </Button>
            </div>
        );
    }
    return <></>
}

export default CookieConsentForm;