// Package imports:
import React from 'react';
import cx from 'classnames';
// // Component imports:

// Service imports:
// Type imports:
type wrapStyles = 'top';
interface IProps {
    source: string,
    title: string,
    description?: JSX.Element,
    mobileWrapStyle?: wrapStyles,
}

const SmallPrint: React.FC<IProps> = ({
    source,
    title,
    description,
    mobileWrapStyle,
    ...props
}) => (
    <div className={cx('IR_smallPrint', mobileWrapStyle)}>
        <div className='image'>
            <img src={source} alt="document" />
        </div>
        <div className='title-description'>
            <h4 className='title'>{title}</h4>
            {description ? description : null}
        </div>
    </div>
)

export default SmallPrint;