import React from 'react';
import cx from 'classnames';

interface IInput {
    required: boolean,
    type: 'text' | 'email' | 'textarea' | 'password' | 'checkbox',
    name: string,
    id: string,
    label: string,
    className?: string,
    value: string,
    onChange: (a: string) => void,
}
const Input:React.FC<IInput> = ({
    required,
    type = 'text',
    name,
    id,
    label,
    className,
    value,
    onChange,
}) => (
    <div className='IR_input'>
        {type === 'textarea'
        ? <textarea
            required={required}
            name={name}
            className={cx("form-control", className)}
            id={id}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            />
        : <input
            required={required}
            type={type}
            name={name}
            className={cx("form-control", className, {'has-value': value !== ""})}
            id={id}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            />}
        <label className="form-input-label">
            {label}
        </label>
    </div>
)

export default Input;