import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Components/Ui-elements/Button/Button';
import Footer from '../../Components/Ui-elements/Footer';
import Header from '../../Components/Ui-elements/Header';
import { Page, Shell } from '../../Components/Ui-elements/StyledComponents/StyledComponents';
import { IUserInfo } from '../../Types/GeneralTypes';

interface IErrorPage {
    errorCode: number,

}
const ErrorPage:React.FC<IErrorPage & IUserInfo> = ({
    errorCode
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    const { title, message, description } = (errorCode === 403) ? {
        title: '403: Aðgangur bannaður',
        message: <p>Þú hefur ekki tilskilin réttindi fyrir þessa síðu.</p>,
        description: ''
    } : (errorCode === 404) ? {
        title: 'Page not found',
        message: <><p className='reverseLetter'>S</p><span>omething ha</span><p className='reverseLetter'>s</p><span> gone wrong</span></>,
        description: <p className='error-detail-message'>Sorry but the page you are looking for was not found. It may have been <br className='breakpoint'/> renamed, deleted or possibly never existed.</p>
    } : (errorCode === 410) ? {
        title: '410: Síðu eytt',
        message: <p>Síðan sem þú ert að leita að er ekki lengur til.</p>,
        description: ''
    } : (errorCode === 500) ? {
        title: '500: Innri netþjónsvilla',
        message: <p>Svo virðist sem netþjónninn hafi lent í aðstæðum sem hann veit ekki hvernig á að höndla.</p>,
        description: ''
    } : {
        title: 'Úps... þetta er vandræðalegt',
        message: <p>Mögulega er sambandsleysi við vefþjón eða síðan sem þú ert að leita að er ekki lengur til.</p>,
        description: ''
    }

    return (
        <>
            <div className="IR_errorPage">
                <Header transparentTheme={true} />
                <Page>
                    <Shell>
                        <div className="content">

                            <div className='headerWrapper'>
                                <h1 className='error-code'>{errorCode} Error&nbsp;</h1>
                                <h1 className='error-title'>- {title}</h1>
                            </div>

                            <h3 className='error-message'>{message}</h3>
                            {description}
                            <div className='navigationWrapper'>
                                <Button
                                    size='lg'
                                    buttonType='whiteAndBlue'
                                    url='/our-widgets'>View IR widgets</Button>
                                <Link to={'/'} className="whiteLink">
                                    Or go to Front page
                                    <FontAwesomeIcon className='fa-icon' icon={faCaretRight} />
                                </Link>
                            </div>
                        </div>
                    </Shell>
                </Page>
            </div>
            <Footer/>
        </>
    )
} 

export default ErrorPage;