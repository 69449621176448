// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faInfoCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
// Component imports:
// import Link from '../../ui-elements/Link/Link';
// Service imports:
// Type imports:

type AlertType = 'success' | 'info' | 'alert' | 'error';

interface IProps {
    type: AlertType,
    withCloseIcon?: boolean,
    headText: string,
    text?: JSX.Element | string
    closeAlert?: () => void,
    moreInfoLink?: string,
    children?: React.ReactNode;
}

const icon = {
    'success': faCheckCircle,
    'info': faInfoCircle,
    'alert': faExclamationCircle,
    'error': faExclamationTriangle
};

const Alert: React.FC<IProps> = ({
    type,
    withCloseIcon,
    headText,
    text,
    closeAlert,
    moreInfoLink,
    children
}) => {
    const [show, setShow] = useState(true);
    return (
        <div className={cx('KCL_alert', type, text ? 'lg' : 'sm', { 'dismissed': withCloseIcon && closeAlert === undefined && !show })}>
            <span className="icon-and-header">
                <div className='leftWrapper'>
                    <FontAwesomeIcon icon={icon[type]} className="icon" />
                    <p className="head-text">{headText}</p>
                </div>
                {withCloseIcon && <button onClick={closeAlert ?? (() => {setShow(false)})} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>}
            </span>
            {text && <div className="main-text">
                        <div>
                            {text}
                        </div>
                {moreInfoLink && <Link to={moreInfoLink} >Nánari upplýsingar</Link>}
            </div>}
        </div>
    );
}

export default Alert;