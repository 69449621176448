import React from "react";

import Button from "../Button/Button";
const InterestedModal: React.FC = () => {

  return (
      <div className="IR_interestedModal">
          <h3>Are you interested yet?</h3>
          <div className="buttonWrapper">
              <Button url= "/demos" buttonType="reverseTransparent">View demos</Button>
              <span>or</span>
              <Button url= "/get-started" buttonType="reverseTransparent">Get started</Button>
          </div>
    </div>
  );
}

export default InterestedModal;