// Package imports:
import React from 'react';
import cx from 'classnames';
// // Component imports:
// import Spinner from 'react-bootstrap/Spinner';

// Service imports:
// Type imports:

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
interface IOwnProps {
    active?: boolean,
}

type Props = IOwnProps & ButtonProps;

const ActiveButton: React.FC<Props> = ({
    active = false,
    className,
    children,
    ...props
}) => {
    return <button className={cx('IR_activeButton', className, {'active' : active})} {...props}>
        <span className='buttonText'>{children}</span>
    </button>
}

export default ActiveButton;