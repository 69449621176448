// Package imports:
import React from 'react';
import cx from 'classnames';
// // Component imports:
// import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import PulseLoader from 'react-spinners/PulseLoader';
import { Link } from 'react-router-dom';

// Service imports:
// Type imports:

type ButtonTypes = 'primary' | 'submit' | 'secondary' | 'whiteAndBlue' | 'transparent' | 'reverseTransparent';
type ButtonSizes = 'sm' | 'md' | 'lg';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
interface IOwnProps {
    buttonType?: ButtonTypes,
    size?: ButtonSizes,
    icon?: IconProp,
    showLoading?: boolean
    url?: string,
    target?: string,
}

type Props = IOwnProps & ButtonProps;

const Button: React.FC<Props> = ({
    buttonType = 'primary',
    size = 'md',
    disabled = false,
    className,
    children,
    url,
    target,
    icon,
    showLoading,
    ...props
}) => {
    if (url?.includes('://www.')) {
        return <a href={url} target={target} className={cx('IR_button', buttonType, size, {'disabled': disabled, 'with-icon': icon}, className)}>
            {children}
            {icon && (
                <FontAwesomeIcon icon={faLock} className="icon-fa" />
            )}
        </a>
    } else if (url){
        return <Link to={url} target={target} className={cx('IR_button', buttonType, size, {'disabled': disabled, 'with-icon': icon}, className)}>
            {children}
            {icon && (
                <FontAwesomeIcon icon={faLock} className="icon-fa" />
            )}
        </Link>

    }
    return <button className={cx('IR_button', buttonType, size, {'disabled': disabled, 'with-icon': icon}, className)} {...props}>
        <span className='buttonText'>
            {
                (showLoading)
                    ? <>
                    <div style={{'height': '0px'}} >
                        <PulseLoader 
                            speedMultiplier={0.6}
                            color="#ffF"
                            size={5}/>
                    </div>
                    <span style={{'visibility': 'hidden'}}>{children}</span>
                </>
              : <div style={{'display': 'inline-block'}}>{children}</div>
            }
        </span>
        {icon && (
            <FontAwesomeIcon icon={faLock} className="icon-fa" />
        )}
    </button>
}

export default Button;