//package imports:
import React, { useEffect, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cx from 'classnames';
import {useAuthUser} from 'react-auth-kit'
import { Link, useParams } from "react-router-dom";
import Toggle from "react-toggle";
import { Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCaretLeft } from "@fortawesome/pro-solid-svg-icons";

//Component imports:
import Header from "../../Components/Ui-elements/Header";
import Footer from "../../Components/Ui-elements/Footer";
import Alert from "../../Components/Ui-elements/Alert/Alert";
import { Page, Shell } from "../../Components/Ui-elements/StyledComponents/StyledComponents";
import Checkbox from "../../Components/Ui-elements/Checkbox/Checkbox";
import ColorPicker from "../../Components/Ui-elements/ColorPicker/ColorPicker";
import NumberInput from "../../Components/Ui-elements/NumberInput/NumberInput";
import SelectDropDown from "../../Components/Ui-elements/SelectDropDown/SelectDropDown";
import LanguageDropDown from "../../Components/Ui-elements/DropDown/LanguageDropDown";

//Assets imports:
import DKK from '../../Assets/Images/Flags/DKK.svg';
import FIN from '../../Assets/Images/Flags/FIN.svg';
import GBP from '../../Assets/Images/Flags/GBP.svg';
import ISK from '../../Assets/Images/Flags/ISK.svg';
import SEK from '../../Assets/Images/Flags/SEK.svg';

//utils imports
import { TOAST_DELAY } from "../../Utils/func";

//options imports
import {
  ISettings,
  FinancialSummaryV2_Options,
  PerformanceV2_Options,
  LastTradesV2_Options,
  KeyFiguresV2_Options,
  HistoricalCalculatorV2_Options,
  HistoricalPriceV2_Options,
  LatestNewsV2_Options,
  MarketDataV2_Options,
  ShareBoxAV2_Options,
  ShareBoxBV2_Options,
  ShareHoldersLargeV2_Options,
  ShareInformation_Options,
  StockLargeV2_Options,
  AnnualInterimAccountsV2_Options,
  StockSmallV2_Options,
  StockTickerV2_Options,
  TopBannerV2_Options,
  SmallStickerV2_Options } from "./WidgetOptions";
import Input from "../../Components/Ui-elements/Input/Input";
export interface IDropDownData {
    title: string,
    imageSrc: string,
    locale: string
}

const FONTS: string[] = [
  "Advent Pro",
  "Alegreya",
  "Cairo",
  "Chakra Petch",
  "Cormorant",
  "DM Mono",
  "Fira Sans",
  "Heebo",
  "Josefin Slab",
  "Lato",
  "Libre Franklin",
  "Mali",
  "Merriweather",
  "Montserrat",
  "Noto Sans JP",
  "Open Sans",
  "Poppins",
  "PT Sans",
  "Raleway",
  "Red Hat Display",
  "Roboto",
  "Rubik",
  "PT Sans",
  "Source Sans Pro",
  "Spectral",
  "Spartan",
  "Oxanium",
  "Open Sans",
  "Raleway",
  "Spartan",
  "Titillium Web",
  "Ubuntu",
  "Work Sans",
  "Noto Sans",
  "Libre Baskerville",
  "Montserrat"
]

const LANGUAGE: IDropDownData[] = [
  {
      title: 'English',
      imageSrc: GBP,
      locale: "en"
  },{
      title: 'Icelandic',
      imageSrc: ISK,
      locale: "is"
  }, {
      title: 'Danish',
      imageSrc: DKK,
      locale: 'da'
  }, {
      title: 'Finnish',
      imageSrc: FIN,
      locale: 'fi'
  }, {
      title: 'Swedish',
      imageSrc: SEK,
      locale: "se"
  },
]
// const LANGUAGE: string[] = [ 'English', 'Icelandic', 'Danish', 'Finnish', 'Swedish' ]

interface IOptionsToggle {
  title: string,
  function: () => void,
  value: boolean,
  disabled?: boolean,
}

interface IOptionsColor {
  title: string,
  function: (e?: string) => void,
  value: string,
  disabled?: boolean,
}
export const getInitialSettings = (widget: string | undefined) => {
    switch(widget){
      case 'annual-interim-accounts': return AnnualInterimAccountsV2_Options;
      case 'performance': return PerformanceV2_Options;
      case 'share-box-a' : return ShareBoxAV2_Options;
      case 'share-box-b' : return ShareBoxBV2_Options;
      case 'historical-price': return HistoricalPriceV2_Options;
      case 'shareholders-large': return ShareHoldersLargeV2_Options;
      case 'latest-news': return LatestNewsV2_Options;
      case 'last-trades': return LastTradesV2_Options;
      case 'key-figures': return KeyFiguresV2_Options;
      case 'financial-summary': return FinancialSummaryV2_Options;
      case 'calculator': return HistoricalCalculatorV2_Options;
      case 'small-sticker': return SmallStickerV2_Options;
      case 'share-information': return ShareInformation_Options;
      case 'market-data': return MarketDataV2_Options;
      case 'stock-large': return StockLargeV2_Options;
      case 'stock-small': return StockSmallV2_Options;
      case 'stock-ticker': return StockTickerV2_Options;
      case 'top-banner': return TopBannerV2_Options;
      default: return TopBannerV2_Options;
    }
  }

type selectedOptionType = 'Statistics area' | 'Statistics to display' | 'Border size' | 'Colors';

const SingleWidget:React.FC = () => {
  const {id} = useParams()
  const user = useAuthUser();
  let symbol = user()?.email.split("@")[0].toUpperCase();
  // const acceptedSymbols = [
  //   'FESTI',
  //   'LIPI',
  //   'EIK',
  //   'EIM',
  //   'LEDIBOND',
  //   'SIGNB',
  //   'BRIM',
  //   'ICEAIR',
  //   'SVN',
  //   'PLAY',
  //   'SOLID',
  //   'ICESEA',
  //   'EIR',
  //   'WIRTEK',
  //   'HYPE',
  //   'KLAPPIR',
  //   'SIMINN',
  //   'LEMON',
  //   "ARION"];
  // if(!acceptedSymbols.includes(symbol)) symbol = "ARION";
  if(symbol === 'KLAPPIR') symbol = "KLAPP B";
  if(symbol === 'USER') symbol = "ARION";

  const [settings, setSettings] = React.useState<ISettings>({...getInitialSettings(id), ...{symbol: symbol}});
  const [contactResponse, setContactResponse] = React.useState<JSX.Element | null>(null);
  const [showToast, setShowToast] = React.useState(false);

  //mobile options
  const [selectedOption, setSelectedOption] = React.useState<selectedOptionType | null>(null)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if(symbol !== null){
      setSettings({...settings, ...{symbol: symbol}})
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol])

  const displayWidget = (widget: string | undefined) => {
      switch(widget){
        case 'share-box-a' : return <share-box-a-v2 {...settings}/>
        case 'share-box-b' : return <share-box-b-v2 {...settings}/>
        case 'historical-price': return <historical-price-v2 {...settings}/>
        case 'shareholders-large': return <shareholders-large-v2 {...settings}/>
        case 'shareholders-small': return <shareholders-large-v2 {...settings} small={true}/>
        case 'latest-news': return <latest-news-v2  {...settings}/>
        case 'financial-summary': return <financial-summary-v2 {...settings}/>
        case 'calculator': return <calculator-v2 {...settings}/>
        case 'share-information': return <share-information {...settings}/>
        case 'market-data': return <market-data-v2 {...settings}/>
        case 'stock-large': return <stock-large-v2 {...settings}/>
        case 'stock-small': return <stock-small-v2 {...settings}/>
        case 'stock-ticker': return <stock-ticker-v2 {...settings} />
        case 'top-banner': return <top-banner-v2 {...settings} />
        case 'annual-interim-accounts': return <annual-interim-accounts-v2 {...settings} />
        case 'last-trades': return <last-trades-v2 {...settings} />
        case 'key-figures': return <key-figures-v2 {...settings} />
        case 'performance': return <performance-v2 {...settings} />
        case 'small-sticker': return <small-sticker-v2 {...settings} />
        default: return null;
      }
  };
  const TOGGLES = useMemo(() => {
    const options: IOptionsToggle[] = [];
    if(settings.shadow !== undefined) options.push({
      title: 'Shadow',
      function: () => setSettings({...settings, ...{shadow: !settings.shadow}}),
      value: settings.shadow
    })
    if(settings.auto_width !== undefined) options.push({
      title: 'Auto width',
      function: () => setSettings({...settings, ...{auto_width: !settings.auto_width}}),
      value: settings.auto_width
    })
    if(settings.transp_backgr !== undefined) options.push({
      title: 'transparent background',
      function: () => setSettings({...settings, ...{transp_backgr: !settings.transp_backgr}}),
      value: settings.transp_backgr
    })
    if(settings.keldan !== undefined) options.push({
      title: 'keldan',
      function: () => setSettings({...settings, ...{ keldan: !settings.keldan}}),
      value: settings.keldan,
      disabled: !settings.trademark
    })
    if(settings.trademark !== undefined) options.push({
      title: 'trademark',
      function: () => setSettings({...settings, ...{ trademark: !settings.trademark}}),
      value: settings.trademark ?? false
    })
    if(settings.logo_on !== undefined) options.push({
      title: 'logo on',
      function: () => setSettings({...settings, ...{ logo_on: !settings.logo_on}}),
      value: settings.logo_on ?? false
    })
    if(settings.header_on !== undefined) options.push({
      title: 'header on',
      function: () => setSettings({...settings, ...{ header_on: !settings.header_on}}),
      value: settings.header_on
    })
    if(settings.small !== undefined) options.push({
      title: 'small',
      function: () => setSettings({...settings, ...{ small: !settings.small}}),
      value: settings.small
    })
    if(settings.chart_on !== undefined) options.push({
      title: 'charton',
      function: () => setSettings({...settings, ...{ chart_on: !settings.chart_on}}),
      value: settings.chart_on
    })
    if(settings.column_layout !== undefined) options.push({
      title: 'column layout',
      function: () => setSettings({...settings, ...{ column_layout: !settings.column_layout}}),
      value: settings.column_layout
    })
    if(settings.netincomechart_on !== undefined) options.push({
      title: 'net income chart on',
      function: () => setSettings({...settings, ...{ netincomechart_on: !settings.netincomechart_on}}),
      value: settings.netincomechart_on
    })
    if(settings.profitlosschart_on !== undefined) options.push({
      title: 'profit/loss chart on',
      function: () => setSettings({...settings, ...{ profitlosschart_on: !settings.profitlosschart_on}}),
      value: settings.profitlosschart_on
    })
    if(settings.excel_download_button !== undefined) options.push({
      title: 'Excel takki',
      function: () => setSettings({...settings, ...{ excel_download_button: !settings.excel_download_button}}),
      value: settings.excel_download_button
    })
    if(settings.no_wrap !== undefined) options.push({
      title: 'Flex no wrap',
      function: () => setSettings({...settings, ...{ no_wrap: !settings.no_wrap}}),
      value: settings.no_wrap
    })
    if(settings.hover_on !== undefined) options.push({
      title: 'hover on',
      function: () => setSettings({...settings, ...{ hover_on: !settings.hover_on}}),
      value: settings.hover_on
    })

    return options
  }, [settings])

  const CHECKBOXES = useMemo(() => {
    const options: IOptionsToggle[] = [];
    if(settings.latest !== undefined) options.push({
      title: 'latest',
      function: () => setSettings({...settings, ...{ latest: !settings.latest}}),
      value: settings.latest
    })
    if(settings.volume !== undefined) options.push({
      title: 'volume',
      function: () => setSettings({...settings, ...{ volume: !settings.volume}}),
      value: settings.volume
    })
    if(settings.high !== undefined) options.push({
      title: 'high',
      function: () => setSettings({...settings, ...{ high: !settings.high}}),
      value: settings.high
    })
    if(settings.low !== undefined) options.push({
      title: 'low',
      function: () => setSettings({...settings, ...{ low: !settings.low}}),
      value: settings.low
    })
    if(settings.trades !== undefined) options.push({
      title: 'trades',
      function: () => setSettings({...settings, ...{ trades: !settings.trades}}),
      value: settings.trades
    })
    if(settings.open !== undefined) options.push({
      title: 'open',
      function: () => setSettings({...settings, ...{ open: !settings.open}}),
      value: settings.open
    })
    if(settings.bid !== undefined) options.push({
      title: 'bid',
      function: () => setSettings({...settings, ...{ bid: !settings.bid}}),
      value: settings.bid
    })
    if(settings.ask !== undefined) options.push({
      title: 'ask',
      function: () => setSettings({...settings, ...{ ask: !settings.ask}}),
      value: settings.ask
    })
    if(settings.currency !== undefined) options.push({
      title: 'currency',
      function: () => setSettings({...settings, ...{ currency: !settings.currency}}),
      value: settings.currency
    })
    if(settings.currency_on !== undefined) options.push({
      title: 'currency',
      function: () => setSettings({...settings, ...{ currency_on: !settings.currency_on}}),
      value: settings.currency_on
    })
    if(settings.mcap !== undefined) options.push({
      title: 'Market cap',
      function: () => setSettings({...settings, ...{ mcap: !settings.mcap}}),
      value: settings.mcap
    })
    if(settings.seven_days !== undefined) options.push({
      title: '7 days',
      function: () => setSettings({...settings, ...{ seven_days: !settings.seven_days}}),
      value: settings.seven_days
    })
    if(settings.one_m !== undefined) options.push({
      title: '1 month',
      function: () => setSettings({...settings, ...{ one_m: !settings.one_m}}),
      value: settings.one_m
    })
    if(settings.three_m !== undefined) options.push({
      title: '3 month',
      function: () => setSettings({...settings, ...{ three_m: !settings.three_m}}),
      value: settings.three_m
    })
    if(settings.one_y !== undefined) options.push({
      title: '1 year',
      function: () => setSettings({...settings, ...{ one_y: !settings.one_y}}),
      value: settings.one_y
    })
    if(settings.range !== undefined) options.push({
      title: 'range',
      function: () => setSettings({...settings, ...{ range: !settings.range}}),
      value: settings.range
    })
    if(settings.market !== undefined) options.push({
      title: 'market',
      function: () => setSettings({...settings, ...{ market: !settings.market}}),
      value: settings.market
    })
    if(settings.symbol_on !== undefined) options.push({
      title: 'symbol',
      function: () => setSettings({...settings, ...{ symbol_on: !settings.symbol_on}}),
      value: settings.symbol_on
    })
    if(settings.isin !== undefined) options.push({
      title: 'isin',
      function: () => setSettings({...settings, ...{ isin: !settings.isin}}),
      value: settings.isin
    })
    if(settings.issued_shares !== undefined) options.push({
      title: 'issued shares',
      function: () => setSettings({...settings, ...{ issued_shares: !settings.issued_shares}}),
      value: settings.issued_shares
    })
    if(settings.outstanding_shares !== undefined) options.push({
      title: 'outstanding shares',
      function: () => setSettings({...settings, ...{ outstanding_shares: !settings.outstanding_shares}}),
      value: settings.outstanding_shares
    })
    if(settings.own_shares !== undefined) options.push({
      title: 'own shares',
      function: () => setSettings({...settings, ...{ own_shares: !settings.own_shares}}),
      value: settings.own_shares
    })
    if(settings.date !== undefined) options.push({
      title: 'Date',
      function: () => setSettings({...settings, ...{ date: !settings.date}}),
      value: settings.date
    })
    if(settings.close !== undefined) options.push({
      title: 'close',
      function: () => setSettings({...settings, ...{ close: !settings.close}}),
      value: settings.close
    })
    if(settings.historical !== undefined) options.push({
      title: 'historical',
      function: () => setSettings({...settings, ...{ historical: !settings.historical}}),
      value: settings.historical
    })
    
    return options
  }, [settings])

  const COLORS = useMemo(() => {
    const options: IOptionsColor[] = [];
    if(settings.background) options.push({
      title: 'background',
      function: (color?: string) => setSettings({...settings, ...{background: color ?? settings.background}}),
      value: settings.background,
      disabled: settings.transp_backgr,
    })
    if(settings.trademark_color) options.push({
      title: 'trademark Color',
      function: (color?: string) => setSettings({...settings, ...{trademark_color: color ?? settings.trademark_color}}),
      value: settings.trademark_color,
      disabled: !settings.trademark,
    })
    if(settings.borderColor) options.push({
      title: 'border color',
      function: (color?: string) => setSettings({...settings, ...{border: `${color} ${settings.borderSize}px solid` ?? settings.border, borderColor: color ?? settings.borderColor}}),
      value: settings.borderColor,
    })
    if(settings.title_color) options.push({
      title: 'title color',
      function: (color?: string) => setSettings({...settings, ...{title_color: color ?? settings.title_color}}),
      value: settings.title_color,
    })
    if(settings.value_color) options.push({
      title: 'value color',
      function: (color?: string) => setSettings({...settings, ...{value_color: color ?? settings.value_color}}),
      value: settings.value_color,
    })
    if(settings.pos_price_color) options.push({
      title: 'positive Price Color',
      function: (color?: string) => setSettings({...settings, ...{pos_price_color: color ?? settings.pos_price_color}}),
      value: settings.pos_price_color,
    })
    if(settings.neg_price_color) options.push({
      title: 'negative Price Color',
      function: (color?: string) => setSettings({...settings, ...{neg_price_color: color ?? settings.neg_price_color}}),
      value: settings.neg_price_color,
    })
    if(settings.trading_open_color) options.push({
      title: 'trading Open Color',
      function: (color?: string) => setSettings({...settings, ...{trading_open_color: color ?? settings.trading_open_color}}),
      value: settings.trading_open_color,
    })
    if(settings.trading_closed_color) options.push({
      title: 'trading closed Color',
      function: (color?: string) => setSettings({...settings, ...{trading_closed_color: color ?? settings.trading_closed_color}}),
      value: settings.trading_closed_color,
    })
    if(settings.line_color) options.push({
      title: 'line color',
      function: (color?: string) => setSettings({...settings, ...{line_color: color ?? settings.line_color}}),
      value: settings.line_color,
    })
    if(settings.link_color) options.push({
      title: 'link color',
      function: (color?: string) => setSettings({...settings, ...{link_color: color ?? settings.link_color}}),
      value: settings.link_color,
    })
    if(settings.date_color) options.push({
      title: 'date color',
      function: (color?: string) => setSettings({...settings, ...{date_color: color ?? settings.date_color}}),
      value: settings.date_color,
    })
    if(settings.hover_text_color) options.push({
      title: 'hover text color',
      function: (color?: string) => setSettings({...settings, ...{hover_text_color: color ?? settings.hover_text_color}}),
      value: settings.hover_text_color,
      disabled: !settings.hover_on
    })
    if(settings.hover_row_color) options.push({
      title: 'hover row color',
      function: (color?: string) => setSettings({...settings, ...{hover_row_color: color ?? settings.hover_row_color}}),
      value: settings.hover_row_color,
      disabled: !settings.hover_on
    })

    //piechart
    if(settings.header_color) options.push({
      title: 'header color',
      function: (color?: string) => setSettings({...settings, ...{header_color: color ?? settings.header_color}}),
      value: settings.header_color,
    })
    if(settings.text_color) options.push({
      title: 'text color',
      function: (color?: string) => setSettings({...settings, ...{text_color: color ?? settings.text_color}}),
      value: settings.text_color,
    })
    if(settings.primary_color) options.push({
      title: 'primary color',
      function: (color?: string) => setSettings({...settings, ...{primary_color: color ?? settings.primary_color}}),
      value: settings.primary_color,
    })
    if(settings.secondary_color) options.push({
      title: 'secondary color',
      function: (color?: string) => setSettings({...settings, ...{secondary_color: color ?? settings.secondary_color}}),
      value: settings.secondary_color,
    })
    if(settings.others_color) options.push({
      title: 'others color',
      function: (color?: string) => setSettings({...settings, ...{others_color: color ?? settings.others_color}}),
      value: settings.others_color,
    })
    if(settings.y_axis_color) options.push({
      title: 'Y-axis color',
      function: (color?: string) => setSettings({...settings, ...{y_axis_color: color ?? settings.y_axis_color}}),
      value: settings.y_axis_color,
    })
    if(settings.x_axis_color) options.push({
      title: 'X-axis color',
      function: (color?: string) => setSettings({...settings, ...{x_axis_color: color ?? settings.x_axis_color}}),
      value: settings.x_axis_color,
    })
    if(settings.legend_color) options.push({
      title: 'Legend color',
      function: (color?: string) => setSettings({...settings, ...{legend_color: color ?? settings.legend_color}}),
      value: settings.legend_color,
    })
    if(settings.chart_line_color) options.push({
      title: 'chart line color',
      function: (color?: string) => setSettings({...settings, ...{chart_line_color: color ?? settings.chart_line_color}}),
      value: settings.chart_line_color,
    })
    if(settings.table_line_color) options.push({
      title: 'table line color',
      function: (color?: string) => setSettings({...settings, ...{table_line_color: color ?? settings.table_line_color}}),
      value: settings.table_line_color,
    })
    // if(settings.buttonColor) options.push({
    //   title: 'button color',
    //   function: (color?: string) => setSettings({...settings, ...{buttonColor: color ?? settings.buttonColor}}),
    //   value: settings.buttonColor,
    // })
    if(settings.area_color) options.push({
      title: 'area color',
      function: (color?: string) => setSettings({...settings, ...{area_color: color ?? settings.area_color}}),
      value: settings.area_color,
    })
    if(settings.volume_color) options.push({
      title: 'volume color',
      function: (color?: string) => setSettings({...settings, ...{volume_color: color ?? settings.volume_color}}),
      value: settings.volume_color,
    })
    if(settings.icon_color) options.push({
      title: 'icon color',
      function: (color?: string) => setSettings({...settings, ...{icon_color: color ?? settings.icon_color}}),
      value: settings.icon_color,
    })
    if(settings.selector_text_color) options.push({
      title: 'input value color',
      function: (color?: string) => setSettings({...settings, ...{selector_text_color: color ?? settings.selector_text_color}}),
      value: settings.selector_text_color,
    })
    if(settings.selector_label_color) options.push({
      title: 'input label color',
      function: (color?: string) => setSettings({...settings, ...{selector_label_color: color ?? settings.selector_label_color}}),
      value: settings.selector_label_color,
    })
    // if(settings.thBackground) options.push({
    //   title: 'Table head background color',
    //   function: (color?: string) => setSettings({...settings, ...{thBackground: color ?? settings.thBackground}}),
    //   value: settings.thBackground,
    // })
    // if(settings.textColor) options.push({
    //   title: 'text color',
    //   function: (color?: string) => setSettings({...settings, ...{textColor: color ?? settings.textColor}}),
    //   value: settings.textColor,
    // })
    // if(settings.thColor) options.push({
    //   title: 'table head text color',
    //   function: (color?: string) => setSettings({...settings, ...{thColor: color ?? settings.thColor}}),
    //   value: settings.thColor,
    // })
    // if(settings.largestColor) options.push({
    //   title: 'largestColor',
    //   function: (color?: string) => setSettings({...settings, ...{largestColor: color ?? settings.largestColor}}),
    //   value: settings.largestColor,
    // })
    // if(settings.mediumColor) options.push({
    //   title: 'mediumColor',
    //   function: (color?: string) => setSettings({...settings, ...{mediumColor: color ?? settings.mediumColor}}),
    //   value: settings.mediumColor,
    // })
    // if(settings.othersColor) options.push({
    //   title: 'othersColor',
    //   function: (color?: string) => setSettings({...settings, ...{othersColor: color ?? settings.othersColor}}),
    //   value: settings.othersColor,
    // })
    // if(settings.profileColor) options.push({
    //   title: 'profileColor',
    //   function: (color?: string) => setSettings({...settings, ...{profileColor: color ?? settings.profileColor}}),
    //   value: settings.profileColor,
    // })
    return options;
  }, [settings])

  const TEXT_TO_COPY_1 = `<script src="${process.env.REACT_APP_WIDGET_URL}"></script>`
  const displayParameter: string = useMemo(() => {
    const OPTIONS_NOT_TO_PRINT = [ 'borderSize', 'borderColor'];
    const arr: string[] = [];
    for(let i of Object.entries(settings)){
      //prop is empty
      
      if(!OPTIONS_NOT_TO_PRINT.includes(i[0]) && i[1] !== undefined && i[1] !== false && i[1] !== ""){
        arr.push(`${i[0]}="${i[1]}" `)
      }
    }
    return arr.join("")
  },[settings])
  
  const TEXT_TO_COPY_2 = useMemo(() => {return `<${id}-v2 ${displayParameter}></${id}-v2>`;
  },[id, displayParameter])

  const displayVersionToggle = useMemo(() => {
    return <>
    <h1 className="widgetName">{id?.replaceAll('-', ' ') ?? 'component'}</h1>
    </>
  },[id])

  return (
    <>
      <div id="SingleWidget" className="IR_singleIrWidget">
        <Header authenticatedPage />
          <Page>
            <Shell>
              <div className="titleWrapper">
                <div className="backButton">
                  <FontAwesomeIcon className="fa-icon" icon={faCaretLeft} />
                  <Link to={"/all-ir-widgets"}><span className="backText">BACK TO WIDGETS</span></Link>
                </div>

                <div className="name_and_version_wrapper mobile">
                    {displayVersionToggle}
                  </div>
                <span className="customizeText">CUSTOMIZE YOUR WIDGET</span>
              </div>
              <div className="container">
                <div className="left_section">
                  <div className="name_and_version_wrapper">
                    {displayVersionToggle}
                  </div>
                  <div id="widgetBox" className="widgetContainer">
                  {
                    displayWidget(id)
                  }
                  </div>
                  <div className="widgetCode">
                    <h4 className="widgetCodeHeader">Widgets Javascript Code</h4>
                    <span className="widgetCodeDescription">
                      Place this script within the HEAD tag on your site, towards the top.<br/>
                      This script should only be included once on your site.
                    </span>
                    <span className="copyDescription">Click on the box below to copy content to clipboard.</span>
                    <CopyToClipboard
                      onCopy={() => {setShowToast(true); setContactResponse(<Alert type={"success"} headText={"Javascript code copied"} />)}}
                      text={TEXT_TO_COPY_1}
                    >
                      <div className="clipboardContainer">
                        <pre>
                          {TEXT_TO_COPY_1}
                        </pre>
                      </div>
                      </CopyToClipboard>
                    </div>
                    <div className="widgetCode">
                      <h4 className="widgetCodeHeader">Widget Embed Code</h4>
                      <span className="widgetCodeDescription">
                        Place this HTML snippet anywhere you want this widget to appear. <br/>
                        Be sure to set the attributes according to the data you wish to display.
                      </span>
                      <span className="copyDescription">Click on the box below to copy content to clipboard.</span>
                      <CopyToClipboard
                        onCopy={() => {setShowToast(true); setContactResponse(<Alert type={"success"} headText={"Embed code copied"} />)}}
                        text={TEXT_TO_COPY_2}>
                        <div className="clipboardContainer">
                          <pre >
                            {TEXT_TO_COPY_2}
                          </pre>
                        </div>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="right_section">
                    <div className="optionsWrapper desktop">
                      <h3 className="option__title">Statistics Area</h3>
                      <div className="dropDownSelectorWrapper">
                        {
                          settings.text_align && <SelectDropDown
                          initialValue={"left"}
                          returnValue={(e) => setSettings({...settings, ...{text_align: e}})}
                          type="text-align" data={['left', 'center', 'right']}
                          title={"Text align"}
                          />}
                        <SelectDropDown initialValue={settings?.font ?? 'Roboto'} returnValue={(e) => setSettings({...settings, ...{font: e}})} type="fonts" data={FONTS} title={"Font"} />
                       
                      </div>
                      <div className="dropDownSelectorWrapper">
                      {
                          settings.locale &&
                          <LanguageDropDown
                            initialValue={LANGUAGE.find(x => x.locale === settings.locale)?.title ?? 'English'}
                            returnValue={(e) => setSettings({...settings, ...{locale: e}})}
                            data={LANGUAGE}
                            title={"Language"} />
                        }
                        {
                          settings.font_size &&
                          <NumberInput
                          placeholder="Default"
                            value={settings.font_size === 'default' ? 0 : parseInt(settings.font_size)}
                            min={0}
                            max={42}
                            width="50px"
                            label="Font size"
                            onChange={(e) => setSettings({...settings, ...{font_size: e === '0' ? 'default' : `${e}px`}})} />
                        }
                        {
                          settings.bold !== undefined &&
                          <div key={'bold'} style={{display: 'flex'}} onClick={() => setSettings({...settings, ...{bold: !settings.bold}})}>
                            <Checkbox checked={settings.bold ?? false} label={'Bold'} />
                          </div>
                        }
                      </div>
                      <div className="toggleWrapper">
                        {/* toggles */}
                        {
                          (TOGGLES.length > 0) && TOGGLES.map((item, ind) => (
                            <div key={ind} className="option__toggle">
                              <span>{item.title}</span>
                              <Toggle
                                defaultChecked={item.value}
                                icons={{
                                  checked: null,
                                  unchecked: null,
                                }}
                                onChange={() => item.function()}
                                disabled={item.disabled ? true : false }/>
                            </div>
                          ))
                        }
                      </div>
                      {/* checkpoints container */}
                      {
                        (CHECKBOXES.length > 0) && (
                          <>
                            <h4 className="optionTitle">Statistics to display</h4>
                            <div className="checkboxWrapper">
                              {
                                CHECKBOXES.map((item, ind) => (
                                  <div key={ind} className="option__checkbox" onClick={() => item.function()}>
                                    <Checkbox checked={item.value ?? false} label={item.title} />
                                  </div>
                                ))
                              }
                            </div>
                          </>
                        )
                      }
                      {/* border-size */}
                      {
                        (settings?.borderSize) &&(
                        <div className="option__borderSize">
                          <label>Border size</label>
                          <NumberInput
                            max={356}
                            min={0}
                            value={parseInt(settings.borderSize)}
                            onChange={(e: string) => setSettings({...settings, ...{borderSize: e, border: `${settings.borderColor} ${e}px solid`}})}
                            />
                        </div>
                        )
                      }
                      {
                        (settings?.dp_padding) &&(
                        <div className="option__borderSize">
                          <label>Padding</label>
                          <NumberInput
                            placeholder="Default"
                            value={settings.dp_padding === 'default' ? 0 : parseInt(settings.dp_padding)}
                            min={0}
                            max={42}
                            width="50px"
                            onChange={(e) => setSettings({...settings, ...{dp_padding: e === '0' ? 'default' : `${e}px`}})} />
                        </div>
                        )
                      }
                      {
                        (settings?.flex_gap) &&(
                        <div className="option__borderSize">
                          <label>Flex Gap</label>
                          <NumberInput
                            placeholder="Default"
                            value={settings.flex_gap === 'default' ? 0 : parseInt(settings.flex_gap)}
                            min={0}
                            max={42}
                            width="50px"
                            onChange={(e) => setSettings({...settings, ...{flex_gap: e === '0' ? 'default' : `${e}px`}})} />
                        </div>
                        )
                      }

                      {/* border-size */}
                      {
                        (settings?.items_per_column) &&(
                        <div className={cx("option__borderSize", {'disabled': !settings?.column_layout})}>
                          <label>Items per column </label>
                          <NumberInput
                            max={356}
                            min={1}
                            disabled={!settings?.column_layout}
                            value={settings?.items_per_column}
                            onChange={(e: string) => setSettings({...settings, ...{items_per_column: /^\d+$/.test(e) ? (parseInt(e) < 1) ? 1 : parseInt(e) : 1}})}
                            />
                        </div>
                        )
                      }

                      {/* news_limit */}
                      {
                        (settings?.news_limit) &&(
                        <div className="option__borderSize">
                          <label>News limit</label>
                          <NumberInput
                            max={40}
                            min={0}
                            value={parseInt(settings?.news_limit.toString()) ?? '10'}
                            onChange={(e: string) => setSettings({...settings, ...{news_limit: e}})}
                            />
                        </div>
                        )
                      }

                      {/* max_limit */}
                      {
                        (settings?.max_limit) &&(
                        <div className="option__borderSize">
                          <label>max limit</label>
                          <NumberInput
                            max={40}
                            min={0}
                            value={parseInt(settings?.max_limit.toString()) ?? '10'}
                            onChange={(e: string) => setSettings({...settings, ...{max_limit: e}})} />
                        </div>
                      )}
                      {/* min_year */}
                      {
                        (settings?.min_year !== undefined) &&(
                        <div className="option__borderSize">
                          <label>Minimum year:</label>
                          <NumberInput
                            max={new Date().getFullYear()}
                            min={2020}
                            value={parseInt(settings?.min_year.toString()) ?? '10'}
                            onChange={(e: string) => setSettings({...settings, ...{min_year: e}})}
                            />
                        </div>
                        )
                      }

                      {/* logo */}
                      {
                        (settings?.logo !== undefined) &&(
                        <div className="option__logo option__borderSize">
                          <label>Logo url:</label>
                          <Input
                            // disabled={!settings?.logo_on}
                            required={false}
                            type={"text"}
                            name={"logo"}
                            id={"logo"}
                            label={"logo url"}
                            value={settings?.logo ?? ''}
                            onChange={(e: string) => setSettings({...settings, ...{logo: e}})} />
                        </div>
                        )
                      }

                      {/* COLORS */}
                      {
                        (COLORS.length > 0) && (
                          <>
                          <h4 className="optionTitle">Colors</h4>
                          <div className="colorsWrapper">
                            {
                              COLORS.map((item, ind) => (
                                <div key={ind} className="option__color">
                                  <ColorPicker
                                    value={item.value}
                                    label={item.title}
                                    onChange={(e: string) => item.function(e)}
                                    disabled={(item.disabled) ? true : false}
                                    />
                                </div>
                              ))
                            }
                        </div>
                          </>
                        )
                      }
                    </div>
                      {/* MOBILE */}
                      {/* MOBILE */}
                      {/* MOBILE */}
                    {/* Display options for component */}
                    <div className="optionsWrapper mobile">
                      <div className="dropdownBox" >
                        <div className="dropdownBox_head" onClick={() => setSelectedOption((selectedOption === 'Statistics area') ? null : 'Statistics area')}>
                          <span>Statistics area</span>
                          <FontAwesomeIcon className="fa-icon" icon={faAngleDown} />
                        </div>
                        {
                          (selectedOption === 'Statistics area') &&
                          <div className="settingsWrapper">
                            {
                              settings.text_align && <SelectDropDown
                              initialValue={"left"}
                              returnValue={(e) => setSettings({...settings, ...{text_align: e}})}
                              type="text-align" data={['left', 'center', 'right']}
                              title={"Text Align"}
                              />}
                            <SelectDropDown initialValue={settings?.font ?? 'Roboto'} returnValue={(e) => setSettings({...settings, ...{font: e}})} type="fonts" data={FONTS} title={"Font"} />
                            {
                              settings.font_size &&
                              <NumberInput
                              placeholder="Default"
                                value={settings.font_size === 'default' ? 0 : parseInt(settings.font_size)}
                                min={0}
                                max={42}
                                width="50px"
                                label="Font size"
                                onChange={(e) => setSettings({...settings, ...{font_size: e === '0' ? 'default' : `${e}px`}})} />
                            }
                            {settings.locale && <LanguageDropDown
                              initialValue={LANGUAGE.find(x => x.locale === settings.locale)?.title ?? 'English'} 
                              returnValue={(e) => setSettings({...settings, ...{locale: e}})} 
                              data={LANGUAGE} 
                              title={"Language"} />}

                            <div className="toggleWrapper">
                              {/* toggles */}
                              {
                                (TOGGLES.length > 0) && TOGGLES.map((item, ind) => (
                                  <div key={ind} className="option__toggle">
                                    <span>{item.title}</span>
                                    <Toggle
                                      defaultChecked={item.value}
                                      icons={{
                                        checked: null,
                                        unchecked: null,
                                      }}
                                      onChange={() => item.function()}
                                      disabled={item.disabled ? true : false }/>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        }
                      </div>
                      {
                        CHECKBOXES.length > 0 &&
                        <div className="dropdownBox" >
                          <div className="dropdownBox_head" onClick={() => setSelectedOption((selectedOption === 'Statistics to display') ? null : 'Statistics to display')}>
                            <span>Statistics to display</span>
                            <FontAwesomeIcon className="fa-icon" icon={faAngleDown} />
                          </div>
                          {
                            (selectedOption === 'Statistics to display') &&
                            <div className="settingsWrapper">
                                {/* checkboxes */}
                                <div className="checkboxWrapper">
                                  {
                                    CHECKBOXES.map((item, ind) => (
                                      <div key={ind} className="option__checkbox" onClick={() => item.function()}>
                                        <Checkbox checked={item.value ?? false} label={item.title} />
                                      </div>
                                    ))
                                  }
                                  </div>
                              </div>
                          }
                        </div>
                      }

                      {/* border size options */}
                      {
                        settings?.borderSize &&
                        <div className="dropdownBox" >
                          <div className="dropdownBox_head" onClick={() => setSelectedOption((selectedOption === 'Border size') ? null : 'Border size')}>
                            <span>Border size</span>
                            <FontAwesomeIcon className="fa-icon" icon={faAngleDown} />
                          </div>
                          {
                            (selectedOption === 'Border size') &&
                            <div className="settingsWrapper">
                              <div className="option__borderSize">
                                <label>Border size</label>
                                  <NumberInput
                                  max={356}
                                  value={parseInt(settings.borderSize)}
                                  onChange={(e: string) => setSettings({ ...settings, ...{ borderSize: e, border: `${settings.borderColor} ${e}px solid` } })}
                                  min={0}/>
                                </div>
                                {
                                  (settings?.flex_gap) &&(
                                  <div className="option__borderSize">
                                    <label>Flex Gap</label>
                                    <NumberInput
                                      placeholder="Default"
                                      value={settings.flex_gap === 'default' ? 0 : parseInt(settings.flex_gap)}
                                      min={0}
                                      max={42}
                                      width="50px"
                                      // label="Flex gap"
                                      onChange={(e) => setSettings({...settings, ...{flex_gap: e === '0' ? 'default' : `${e}px`}})} />
                                  </div>
                                  )
                                }
                            </div>
                          }
                        </div>
                      }

                      {/*  options */}
                      {
                        COLORS.length > 0 &&
                        <div className="dropdownBox" >
                          <div className="dropdownBox_head" onClick={() => setSelectedOption((selectedOption === 'Colors') ? null : 'Colors')}>
                            <span>Colors</span>
                            <FontAwesomeIcon className="fa-icon" icon={faAngleDown} />
                          </div>
                          {
                            (selectedOption === 'Colors') &&
                            <div className="colorsWrapper settingsWrapper">
                              {
                                COLORS.map((item, ind) => (
                                  <div key={ind} className="option__color">
                                    <ColorPicker
                                      value={item.value}
                                      label={item.title}
                                      onChange={(e: string) => item.function(e)}
                                      disabled={(item.disabled) ? true : false}
                                      />
                                  </div>
                                ))
                              }
                              </div>
                          }
                        </div>
                      }
                    </div>

                  </div>
              </div>
            </Shell>
          </Page>
          <Footer onlyDisplaySecondFooter/>
        </div>

        <ToastContainer className='toastContainer' >
          <Toast onClose={() => setShowToast(false)} autohide delay={TOAST_DELAY} show={showToast}>
              {contactResponse}
          </Toast>
      </ToastContainer>
      </>
  );
}

export default SingleWidget;