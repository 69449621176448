import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

//logos
import icelandair from "../../Assets/Images/Logos/icelandair-logo.svg";
import svn from "../../Assets/Images/Logos/svn-logo.svg";
import wirtek from "../../Assets/Images/Logos/wirtek-logo.svg";
import play from "../../Assets/Images/Logos/play-logo.svg";
import solidclouds from "../../Assets/Images/Logos/solidclouds-logo.svg";
import eik from "../../Assets/Images/Logos/eik-logo.svg";
import icesea from "../../Assets/Images/Logos/icesea-logo.svg";
import lipidor from "../../Assets/Images/Logos/lipidor-logo.svg";
import izafe from "../../Assets/Images/Logos/izafe-logo.svg";
import festi from "../../Assets/Images/Logos/festi-logo.svg";
import hypefactors from "../../Assets/Images/Logos/hypefactors-logo.svg";


import leftControl from "../../Assets/Images/left-arrow.svg";
import rightControl from "../../Assets/Images/right-arrow.svg";

type clients = 'icelandair' | 'svn' | 'wirtek' | 'play' | 'solidclouds' | 'eik' | 'icesea' | 'lipidor' | 'izafe' | 'festi' | 'hypefactors';

interface IClient {
  name: clients | null,
  imageSource: string | null
}

const CLIENTS: IClient[] = [
  {
    name: 'icelandair',
    imageSource: icelandair
  }, {
    name: 'svn',
    imageSource: svn
  }, {
    name: 'wirtek',
    imageSource: wirtek
  }, {
    name: 'play',
    imageSource: play
  }, {
    name: 'solidclouds',
    imageSource: solidclouds
  }, {
    name: 'eik',
    imageSource: eik
  }, {
    name: 'icesea',
    imageSource: icesea
  }, {
    name: 'lipidor',
    imageSource: lipidor
  }, {
    name: 'izafe',
    imageSource: izafe
  }, {
    name: 'festi',
    imageSource: festi
  }, {
    name: 'hypefactors',
    imageSource: hypefactors
  }, 
  {
    name: null,
    imageSource: null
  }
];


const Clients: React.FC = () => (
    <div className="IR_clientCarousel">
        <h5 className='title'>Some of our clients</h5>
        <AliceCarousel
            disableDotsControls
            autoWidth
            mouseTracking
            items={
                CLIENTS.map((item, index) => {
                  //because bug -> cant scroll all the way to end. 
                  // but now the bug is in if you go all the way to the end..
                  //you have to press 2 on go back if you're at the end.. 
                  if(item.name === null || item.imageSource === null){
                    return(<div style={{'width': '160px'}} key={index}></div>)
                  } else {
                    return (
                      <div className="single-company" key={index} data-value={index}>
                          <img src={item.imageSource} alt={item.name} draggable="false"/>
                      </div>
                  )
                  }
              })
            }
            renderPrevButton={( )=> <img className='carousel_btn' src={leftControl} alt="leftControl"/>}
            renderNextButton={( )=> <img className='carousel_btn' src={rightControl} alt="rightControl"/>}
        />
    </div>
);
export default Clients;