// @ts-nocheck
// import { FinancialSummary_Options, HistoricalCalculator_Options, HistoricalPrice_Options, ISettings, LatestNews_Options, MarketData_Options, ShareBoxA_Options, ShareHoldersLarge_Options, ShareInformation_Options, StockLarge_Options, StockTicker_Options, TopBanner_Options } from '../Pages/Authorized/WidgetOptions';
import moment from "moment";
import { AnnualInterimAccountsV2_Options,
  FinancialSummaryV2_Options,
  HistoricalCalculatorV2_Options,
  HistoricalPriceV2_Options,
  LatestNewsV2_Options,
  LastTradesV2_Options,
  KeyFiguresV2_Options,
  SmallStickerV2_Options,
  MarketDataV2_Options,
  ShareBoxAV2_Options,
  ShareBoxBV2_Options,
  ShareHoldersLargeV2_Options,
  ShareInformation_Options,StockSmallV2_Options,StockLargeV2_Options,
  StockTickerV2_Options,
  TopBannerV2_Options,
  PerformanceV2_Options } from '../Pages/Authorized/WidgetOptions';
import ReactGA from 'react-ga4';

export const sendGoogleAnalyticCustomEvent = (category: string, action: string, label: string, value?: string) => {
  // Send a custom event
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: value, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
}
export const getInitialSettings = (widget: string | undefined) => {
  switch(widget){
    case 'annual-interim-accounts': return AnnualInterimAccountsV2_Options;
    case 'share-box-a' : return ShareBoxAV2_Options;
    case 'share-box-b' : return ShareBoxBV2_Options;
    case 'historical-price': return HistoricalPriceV2_Options;
    case 'shareholders-large': return ShareHoldersLargeV2_Options;
    case 'shareholders-small': return ShareHoldersLargeV2_Options;
    case 'latest-news': return LatestNewsV2_Options;
    case 'latest-trades': return LastTradesV2_Options;
    case 'key-figures': return KeyFiguresV2_Options;
    case 'small-sticker': return SmallStickerV2_Options;
    case 'performance': return PerformanceV2_Options;
    case 'financial-summary': return FinancialSummaryV2_Options;
    case 'calculator': return HistoricalCalculatorV2_Options;
    case 'share-information': return ShareInformation_Options;
    case 'market-data': return MarketDataV2_Options;
    case 'stock-large': return StockLargeV2_Options;
    case 'stock-small': return StockSmallV2_Options;
    case 'stock-ticker': return StockTickerV2_Options;
    case 'top-banner': return TopBannerV2_Options;
    default: return TopBannerV2_Options;
  }
}


export const extractApiData = (resp: Array<any>) => {
  return (resp && resp[0]) || [];
};

export const TOAST_DELAY = 5000;

export const formatMillion = (amount: number) => {
  if (amount < 1000000) {
    return (amount / 1000000).toFixed(2) + "Million";
  } else {
    return (
      (amount / 1000000).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      "Million"
    );
  }
};

export const formatMillionShort = (amount: number) => {
  if (amount < 1000000) {
    return (amount / 1000000).toFixed(2) + "M";
  } else {
    return (
      (amount / 1000000).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
    );
  }
};

export const formatNumber = (amount: number) => {
  return amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatThousand = (amount: number) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPercent = (amount: number) => {
  return (amount * 100).toFixed(2).replace(/B(?=(d{3})+(?!d))/g, ",") + "%";
};

export const formatDateTime = (dateString: Date) => {
  return moment(dateString).format("HH:mm MM.DD.YYYY");
};

export const formatDate = (date: any, format = "YYYY-DD-MM") => {
  return typeof date === "object" && date.hasOwnProperty("format")
    ? date.format(format)
    : moment(date).format(format);
};

export const formatTime = (dateString: Date) => {
  return moment(dateString).format("HH:mm:ss");
};

export const isWeekend = () => {
  const day = moment().format("e");
  return day === "0" || day === "6";
};

export const nowDate = () => {
  return moment().format("HH:mm MM.DD.YYYY");
};

export const isPercent = (str: string) =>
  str.toString().indexOf("%") > 0 ? true : null;

export const isIncrement = (str: string) => Math.sign(parseFloat(str) * 100) === 1;

export const turnIntoPropsArray = (propsNameArr: Array<any>, propsValuesArr) => {
  const propsArr: Array<any> = [];
  propsNameArr.forEach((prop, index) => {
    const isPer = isPercent(propsValuesArr[index] || "");
    propsArr.push({
      name: prop,
      value: propsValuesArr[index] || "",
      isPercent: isPer,
    });
  });
  return propsArr;
};

export const shorten = (text, maxLength) => {
  if (text) {
    let ret = text;
    if (ret.length > maxLength) {
      ret = ret.substr(0, maxLength - 3) + "...";
    }
    return ret;
  }
  return null;
};

export const arrayRemove = (arr, arr2) => {
  return arr.filter((x) => arr2.includes(x.name));
};

export const findMatch = (widget_categories, all_categories) => {
  let array = [];
  const missing = all_categories.filter(
    (
      (i) => (a) =>
        a !== widget_categories[i] || !++i
    )(0)
  );

  for (var i = 0; i < all_categories.length; i++) {
    for (var x = 0; x < missing.length; x++) {
      if (all_categories[i] === missing[x]) {
        array.push(i);
      }
    }
  }

  return array;
};

export const quickFilterData = (arr, value) => {
  const filtered = arr.filter(function (e) {
    return e.FiscalYear !== value;
  });

  return filtered;
};

export const quickFilterDataQuarterly = (arr, value) => {
  const filtered = arr.filter(function (e) {
    const tempValue = e.PeriodName + " '" + e.FiscalYear.toString().slice(-2);
    return tempValue !== value;
  });

  return filtered;
};

export const quickFilterMultiple = (arr1, arr2) => {
  return arr1.filter((item) => arr2.includes(item.FiscalYear.toString()));
};

export const quickFilterMultipleQuarterly = (arr1, arr2) => {
  return arr1.filter((item) =>
    arr2.includes(item.PeriodName + " '" + item.FiscalYear.toString().slice(-2))
  );
};

export const removeDuplicatesAndSort = (arr) => {
  let s = new Set(arr);
  let it = s.values();
  const cleanArray = Array.from(it).sort();

  return cleanArray;
};
