import React, { useEffect } from "react";

import Header from "../../../Components/Ui-elements/Header";
import Footer from "../../../Components/Ui-elements/Footer";
import InterestedModal from "../../../Components/Ui-elements/InterestedModal/InterestedModal";
import ParagraphWithImage from "../../../Components/Ui-elements/ParagraphWithImage/ParagraphWithImage";
import EmpowerYourWebsite_browser from "../../../Assets/Images/Empower.svg";
import BuildVisually_browser from "../../../Assets/Images/Build_visually_browser.svg";
import NoCodingNeeded_browser from "../../../Assets/Images/No_coding_needed_browser.svg";
import { Page, ShellBody } from "../../../Components/Ui-elements/StyledComponents/StyledComponents";
import { IUserInfo } from "../../../Types/GeneralTypes";
import HeadSection from "../../../Components/Ui-elements/HeadSection/HeadSection";

// import irWidgetHeroImage from '../../../Assets/Images/irWidgetHeroImage.svg'

const OurWidgets:React.FC<IUserInfo> = ({
  isAuthenticated
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="IR_ourWidgets">
      <Header scroll={true} />
      <Page>
        <div className="hero">
          <div className="hero-text">
            <HeadSection
              title={"IR widgets"}
              question={<span>Mix and match, <br/> build and integrate</span>} 
              description="We’ve got you covered with a comprehensive list of great looking charts, 
              share information, stock quotes, and more for all companies listed on Nasdaq Nordic."
              textAlign="left"
              theme="greenAndPurple"
              halfWidth
              button={{text: 'View demos', url: '/demos'}}
              />
          </div>
          {/* <div>
            <img alt="irwidgetHero" src={irWidgetHeroImage} />
          </div> */}
        </div>
        <ShellBody>
          <div className="examples_section">
            <div className="exampleImages" id="empowerWebsite">
              <ParagraphWithImage
                info={"Richer content"}
                title={"Empower your website"}
                image={<img alt="browserImage" className="browserImage" src={EmpowerYourWebsite_browser} />}
                description={<p>Make your website the destination for your current stakeholders and new investors for accurate financials, news and other key information about your company financials.</p>} />
            </div>
            <div className="exampleImages" id="buildVisually">
              <ParagraphWithImage
                reverse
                info={"What you see is what you get"}
                title={"Build visually"}
                image={<img alt="browserImage" className="browserImage" src={BuildVisually_browser} />}
                description={<p>Each widget provides a rich set of customizable options that you can apply with a single click to perfectly match your corporate brand.</p>} />
            </div>
            <div className="exampleImages" id="noCodingNeeded">
              <ParagraphWithImage
                info={"Easy as copy & paste"}
                title={"No coding needed"}
                image={<img alt="browserImage" className="browserImage" src={NoCodingNeeded_browser} />}
                description={<p>Deploy fully responsive charts and stock market data visualizations on your site without writing a single line of code via short codes!</p>} />
            </div>
          </div>
          <section className="interestedSection jc-center">
            <InterestedModal />
          </section>
        </ShellBody>
      </Page>
      <Footer/>
    </div>
  );
}

export default OurWidgets;