// Package imports:
import React from 'react';
import cx from 'classnames';
import { EyeBrowBeforeH1, H1 } from '../StyledComponents/StyledComponents';
import Button from '../Button/Button';
// // Component imports:

// Service imports:
// Type imports:
interface IProps {
    title: string,
    question: string | JSX.Element,
    description?: string | JSX.Element,
    theme?: 'greenAndPurple'
    textAlign?: 'left' | 'center' | 'right',
    halfWidth?: boolean,
    button?: {text: string, url: string}
}

const HeadSection: React.FC<IProps> = ({
    title,
    question,
    description,
    textAlign = 'center',
    theme,
    halfWidth = false,
    button,
    ...props
}) => (
    <div style={{'textAlign': textAlign}} className={cx('IR_headSection', theme)}>
        <EyeBrowBeforeH1 alignment={textAlign}>{title}</EyeBrowBeforeH1>
        <H1  halfWidth={halfWidth} alignment={textAlign}>{question}</H1>
        {description ? <div><p className={cx({'halfWidth': halfWidth})} style={{'textAlign': textAlign}}>{description}</p></div> : null}
        {
            button && <div className='buttonWrapper'>
                        <Button
                            size="lg"
                            buttonType='submit'
                            url={button.url}>
                            {button.text}
                        </Button>
                    </div>
            }
    </div> 
)

export default HeadSection;