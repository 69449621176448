import enLang from './entries/en-US';
import daLang from './entries/da-DA';
import isLang from './entries/is-IS';
import fiLang from './entries/fi-FI';
import svLang from './entries/sv-SE';

const AppLocale = {
  en: enLang,
  da: daLang,
  is: isLang,
  fi: fiLang,
  se: svLang
};

export default AppLocale;